import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import "./content-modal.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import JSCookie from "js-cookie";
import { DFPSlotsProvider, AdSlot, DFPManager } from "react-dfp";
import ReactGA from "react-ga4";
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  ModalHeader,
  Container,
  Spinner,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import FileSaver from "file-saver";
import publicationJson from "../config/Publications.json";
import OutsideClickHandler from "react-outside-click-handler";
import { X, ZoomIn, ZoomOut, Mail, Download } from "react-feather";
import axios from "axios";
import { Copy, FileText, Image, Printer, Share2, Volume2 } from "react-feather";
import { VolumeX } from "react-feather";
import Modal from "react-modal";

import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ShareIcon from "@material-ui/icons/Share";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PrintIcon from "@material-ui/icons/Print";
import "../Fonts/LibreBaskerville-Regular.ttf";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { XCircle } from "react-feather";
// context
import { AppContext } from "../contexts/AppContext";
import { Helmet } from "react-helmet";

import { displayToastr } from "../helper";
import { VolumeOff } from "@material-ui/icons";
import { articleEvents } from "../functions/cleverTap";

Modal.setAppElement("#root");

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});
export default function ArticleCard(props) {
  const { s3Source, print, toggle, logo } = useContext(AppContext);

  const [views, setViews] = useState(0);
  const [totalShareCount, setTotalShareCount] = useState(0);
  const [shares, setShares] = useState({
    facebook: 0,
    twitter: 0,
    whatsapp: 0,
    email: 0,
  });
  const [isOpen2, setIsOpen2] = useState(false);

  const [articleViewName, setArticleViewName] = useState(null);

  const [articleViewNameLinked, setArticleViewNameLinked] = useState(null);

  const [articleViewData, setArticleViewData] = useState({});

  const [relatedArticleData, setRelatedArticleData] = useState(null);

  const [articleViewDataLinked, setArticleViewDataLinked] = useState(null);

  const [filteredDate, setFilteredDate] = useState({});
  const [linkJsonData, setLinkJsonData] = useState({});

  const [articles, setArticles] = useState([]);

  const [currentPage, setCurrentPage] = useState({});

  const [hasInfoZone, setHasInfoZone] = useState(false);

  const [currArticleIndex, setCurrArticleIndex] = useState(0);

  const [articleName, setArticleName] = useState("");
  const [dateArray, setDateArray] = useState([]);

  const [initialPublication, setInitialPublication] = useState("");

  const [initialEdition, setInitialEdition] = useState("");
  const [TTS, setTTS] = useState("");

  const [isRegional, setIsRegional] = useState(false);
  const [themeColor, setThemeColor] = useState();

  const [pageClickValues, setPageClickValues] = useState({});

  useEffect(() => {
    if (window) {
      window.dataLayer &&
        window.dataLayer.push({
          event: "page_view",
          pagePath: window.location.href,
        });
      ReactGA.event("page_view", {
        event: "page_view",
        category: "page_view",
        action: "page_view",
        label: "page_view",
        section_name: currentPage?.PageTitle ?? "", //required
        page_no: currentPage?.pageNumber ?? "", //required
        print_view: true,
        digital_view: false,
        publication_name: initialPublication?.publicationname,
        edition_name: initialEdition?.editionname,
        article_title: "",
        article_author: "",
      });
    }
  }, []);

  const updateFromOldURL = async () => {
    let publications = publicationJson?.Publications;
    let searchValue = props.location.search
      .split("?article=")?.[1]
      ?.split("/PublicationData/")[1]
      ?.split("/ArticleZoneJson/");
    await setCurrentPage({
      PageName: searchValue[1]
        .split(".json")[0]
        .split("_")
        .slice(0, 4)
        .join(`_${searchValue[0].split("/")[1].toLowerCase()}`),
    });
    await setArticleName(searchValue[1].split(".json")[0]);

    await setDateArray(searchValue[0].split("/").slice(2));

    let publication = await publications.filter(
      (v) =>
        v.publicationcode.toLowerCase() ==
        searchValue[0].split("/")[0].toLowerCase()
    )?.[0];
    await setThemeColor(publication?.theme_color);
    let edition = await publication.editions.filter(
      (v) =>
        v.editionid.toLowerCase() == searchValue[0].split("/")[1].toLowerCase()
    )?.[0];
    if (publication?.publicationcode) {
      await setInitialEdition(edition);
    }
    await getLinkJson(
      publication,
      edition,

      searchValue[0].split("/").slice(2).reverse().join("-")
    );
    await setInitialPublication(publication);
    await setCurrentPublication(publication);
    await fetchPublications();
    await openArticleView(
      searchValue[1].split(".json")[0],
      publication,
      edition,
      "",
      searchValue[0].split("/").slice(2)
    );
  };

  useEffect(() => {
    async function setData() {
      let publications = publicationJson.Publications;
      let oldURL = false;

      if (
        props.location.search
          .split("?article=")?.[1]
          .includes("/PublicationData/")
      ) {
        oldURL = true;
        await updateFromOldURL();
        return;
      }
      if (!oldURL) {
        const article_id = props?.location.search.split("&")[0];
        await setCurrentPage({
          PageName: `${article_id
            .split("?article=")?.[1]
            .split("_")
            .slice(0, 4)
            .join("_")}_${article_id
            .split("?article=")?.[1]
            .split("_")[5]
            .toLowerCase()}`,
        });
        await setArticleName(
          article_id.split("?article=")?.[1].split("_").slice(0, 6).join("_")
        );
        let split = await article_id.split("?article=")?.[1].split("_");

        await setDateArray(
          article_id.split("?article=")?.[1].split("_").slice(0, 3).reverse()
        );

        let publication = await publications.filter(
          (v) => v.publicationcode.toLowerCase() == split[6].toLowerCase()
        )?.[0];
        await setThemeColor(publication?.theme_color);
        let edition = await publication?.editions.filter(
          (v) => v.editionid.toLowerCase() == split[5].toLowerCase()
        )?.[0];
        if (publication?.publicationcode) {
          await setInitialEdition(edition);
        }
        await getLinkJson(
          publication,
          edition,
          props.location.search
            .split("?article=")?.[1]
            .split("_")
            .slice(0, 3)
            .join("-")
        );
        await setInitialPublication(publication);
        await setCurrentPublication(publication);
        await fetchPublications();
        await openArticleView(
          props.location.search
            .split("?article=")?.[1]
            .split("_")
            .slice(0, 6)
            .join("_"),
          publication,
          edition,
          "",
          props.location.search
            .split("?article=")?.[1]
            .split("_")
            .slice(0, 3)
            .reverse()
        );
      }
    }
    setData();
  }, []);
  const links = useRef({});
  const getLinkJson = async (pub, edn, date) => {
    let path = `${publicationJson[process.env.REACT_APP_ENV]}/PublicationData/${
      pub?.publicationcode
    }/${edn?.editionid}/${date.split("-").reverse().join("/")}/LinkJson/${date
      .split("-")
      .join("_")}_${edn?.editionid}.json?v=${date?.Timestamp}`;
    await axios
      .get(path)
      .then(async (res) => {
        await setLinkJsonData(res.data);
        links.current = res.data;
      })
      .catch((err) => {});
  };
  const fetchPublications = () => {
    setArticles({});
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray?.[0]}/${dateArray?.[1]}/${dateArray?.[2]}/PageJson/`;

    let pagename = `${currentPage?.PageName}`;

    path = path_prefix + pagename + ".json?v=" + new Date().getTime();

    if (!path.includes("undefined")) {
      axios
        .get(path)
        .then(async (result) => {
          await setArticles(result?.data);
        })
        .catch((err) => {});
    }
  };
  const getContinuedArticles = async (articleName, articleJsonPath) => {
    let result = false;
    let isLinkTo = false;
    if (links.current?.linkJson) {
      if (links.current?.linkJson && links.current?.linkJson?.length > 0) {
        let filter = links.current.linkJson.filter(
          (v) => v.FromLinkArticle === articleName
        );
        if (filter.length === 0) {
          filter = links.current.linkJson.filter(
            (v) => v.ToLinkArticle === articleName
          );
          if (filter.length > 0) {
            isLinkTo = true;
          }
        }
        if (filter && filter.length > 0) {
          let findArticleLink = filter.filter(
            (v) => v.LinkType === "articleLink"
          );
          let selectedArticleLink = [];
          if (findArticleLink.length > 0) {
            selectedArticleLink = [findArticleLink[findArticleLink.length - 1]];
          }
          // if (filter[0].LinkType === "articleLink") {
          if (selectedArticleLink?.length > 0) {
            let pageNumber = filter[0]?.ArticleLink.split("_")?.[3];

            setArticleViewNameLinked(filter[0]?.ArticleLink);
            await axios
              .get(
                `${articleJsonPath}${pageNumber}/${
                  selectedArticleLink[0]?.ArticleLink
                }.json?v=${new Date().getTime()}`
              )
              .then(async (response) => {
                let array = [];
                for (let i = 0; i < response.data.length; i++) {
                  const ele = response.data[i];
                  let obj = { ...ele, SortOrder: i + 1 };
                  array.push(obj);
                }

                response.data = array;
                const finalArticle = await getFinalArticleArray(
                  response?.data,
                  selectedArticleLink[0]?.ArticleLink
                );
                result = {
                  articleName: selectedArticleLink[0]?.ArticleLink,
                  pageNumber,
                  articleData: finalArticle,
                  isLinkTo,
                };
                setArticleViewDataLinked({
                  ...finalArticle.articleData[0],
                  ArticlePhotographs: finalArticle.photographs ?? [],
                });
                setTimeout(() => {
                  updateInsight("view", finalArticle.articleData[0]);
                }, 1200);
              })
              .catch((err) => {
                setArticleViewDataLinked(null);
              });
          }
        }
      }
    }
    return result;
  };
  const openArticleView = async (
    articleName,
    initialPublication,
    initialEdition,
    zone,
    dates
  ) => {
    let pageNumber = articleName.split("_")?.[3];
    if (zone?.TagName === "Information") setHasInfoZone(true);

    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition?.editionid}/${
      dates.length > 0 ? dates?.join("/") : dateArray?.join("/")
    }/ArticleZoneJson/`;
    let searched = await getContinuedArticles(articleName, path_prefix);
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      interchange = searched?.isLinkTo;
    }

    let arrayToUse = articles.PageContent;

    // if (!articles.PageContent) {
    //   arrayToUse = propsArticles;
    // }
    const articleIndex = arrayToUse?.findIndex(
      (article) => article.ArticleName === articleName
    );
    if (articleIndex !== -1) setCurrArticleIndex(articleIndex);

    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${new Date().getTime()}`;

    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;
        const finalArticle = await getFinalArticleArray(
          response.data,
          articleName,
          searched,
          interchange,
          true
        );
      })

      .catch((err) => {
        console.log("err: ", err);
        setArticleViewData(null);
      });
  };

  const getFinalArticleArray = async (
    data,
    articleName,
    searched,
    interchange,
    isNotFromContinued
  ) => {
    try {
      const articles = [];

      let photographs = data.filter((v) => v.TagName === "Photographs");

      if (photographs.length > 0) {
        photographs = photographs.map((ele, ind) => {
          return {
            ...ele,
            Photograph: ele?.ZoneID,
            ImageCaption: data?.filter(
              (v) =>
                v.TagName === "ImageCaption" &&
                v.SortOrder == ele?.SortOrder + 1
            )?.[0]?.ZoneText,
          };
        });
      }
      let hasInformationTag = false;
      const titles = await data.filter((v) => v.TagName === "ArticleTitle");
      for (let j = 0; j < titles.length; j++) {
        const ele = titles[j];

        const sliceNum = titles.filter((v, i, a) => {
          if (i !== 0 && i === j + 1) {
            return a[i];
          }
        });
        let sliced_list = [];
        if (sliceNum.length > 0) {
          sliced_list = data.slice(
            ele.SortOrder - 1,
            sliceNum[0].SortOrder - 1
          );
        } else {
          sliced_list = data.slice(ele.SortOrder - 1);
        }
        let checkForBlurb = data.filter(
          (v) => v.SortOrder === ele.SortOrder - 1
        );
        if (
          checkForBlurb &&
          checkForBlurb.length > 0 &&
          checkForBlurb?.[0].TagName === "Blurb"
        ) {
          sliced_list.unshift(checkForBlurb?.[0]);
        }
        articles.push(sliced_list);
      }

      if (articles.length > 0) {
        let finalArticleArray = [];
        let allowedTags = ["ArticleTitle", "ArticleBody"];
        for (let i = 0; i < articles.length; i++) {
          const sub_article = articles[i];
          let tts = "";
          let obj = {};

          for (let j = 0; j < sub_article.length; j++) {
            const ele = sub_article[j];

            if (allowedTags.includes(ele.TagName)) {
              tts += ele?.ZoneText?.split("")
                .join(" ")
                .split("<br>")
                .join(" ")
                .split("<b>")
                .join(" ")
                .split("<p>")
                .join(" ")
                .split("</p>")
                .join(" ")
                .split("</b>")
                .join(" ")
                ?.split("<br>`<br>")
                .join("₹")
                .split("<br>`")
                .join("₹")
                .split("`<br>")
                .join("₹")
                .split("`")
                .join("₹")
                .split("<br>¤<br>")
                .join("€")
                .split("<br>¤")
                .join("€")
                .split("¤<br>")
                .join("€")
                .split("¤")
                .join("€")
                ?.split("<br>")
                ?.join("<br/>")
                .split("&lt")
                .join("<")
                .split("&gt;")
                .join(">")
                .split("")
                .join("›");
            }
            if (ele.TagName === "Information") {
              setHasInfoZone(true);
              hasInformationTag = true;
            }
            if (ele.TagName === "LinkTo") {
              let zoneid = ele.ZoneID.split("_");
              zoneid.pop();
              let linkedZone = await searchLink(
                `${s3Source}/PublicationData/${
                  initialPublication?.publicationcode
                }/${initialEdition?.editionid}/${dateArray?.join(
                  "/"
                )}/LinkJson/${dateArray[2]}_${dateArray[1]}_${dateArray[0]}_${
                  initialEdition?.editionid
                }.json?v=${new Date().getTime()}`,
                { ZoneId: zoneid.join("_") }
              );
              const span_link = `<p id='${zoneid.join("_")}' class='${
                linkedZone?.[0]?.PageLink
              }' style='font-family:LibreBaskerville'>${
                (!ele.ZoneText || ele.ZoneText === " ") &&
                linkedZone[0].LinkType === "pageLink"
                  ? `P${Number(linkedZone[0].PageLink.split("_")[3])}`
                  : ele.ZoneText?.split("").join("›")
              }</p>`;
              if (obj.ArticleBody) {
                obj["ArticleBody"] = obj?.["ArticleBody"] + `${span_link}`;
              } else {
                obj["ArticleBody"] = span_link;
              }
            } else if (ele.TagName === "ArticleBody") {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›")
                : ele?.ZoneText?.split("").join("›") + "googleAdDivHere";
            } else {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›").trim()
                : ele?.ZoneText?.split("").join("›").trim();
            }
          }

          finalArticleArray.push({ ...obj, ArticleName: articleName, tts });
        }
        let finalArticle = {
          photographs,
          articleData: finalArticleArray,
        };

        if (isNotFromContinued) {
          if (interchange) {
            if (
              searched &&
              Object.keys(searched).length > 0 &&
              searched?.articleData?.articleData?.length > 1
            ) {
              let related = [
                ...searched?.articleData?.articleData.slice(1),
                ...finalArticle.articleData.slice(1),
              ];
              setRelatedArticleData(related);
            }
            await setArticleViewName(searched?.articleName);
            if (
              !hasInformationTag &&
              !searched?.articleData?.articleData[0]?.ArticleBody?.slice(
                0,
                7
              ) === "<p id='"
            ) {
              let location = await findLocation(
                searched?.articleData?.articleData[0]?.ArticleBody
              );
              if (location && location.length > 0) {
                searched.articleData.articleData[0].ArticleLocation =
                  location.join(" ");
                searched.articleData.articleData[0].ArticleBody =
                  searched?.articleData?.articleData[0]?.ArticleBody?.slice(
                    location.join(" ")?.length
                  );
              }
            }
            setArticleViewData(searched?.articleData?.articleData[0]);
            setArticleViewDataLinked({
              ...finalArticle?.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
            await setArticleViewNameLinked(articleName);
          } else {
            setRelatedArticleData(finalArticle.articleData.slice(1));
            await setArticleViewName(articleName);
            await setArticleViewNameLinked(searched?.articleName);

            if (
              !hasInformationTag &&
              !finalArticle.articleData[0]?.ArticleBody.slice(0, 7) ===
                "<p id='"
            ) {
              let location = await findLocation(
                finalArticle.articleData[0]?.ArticleBody
              );

              if (location && location.length > 0) {
                finalArticle.articleData[0].ArticleLocation =
                  location.join(" ");

                finalArticle.articleData[0].ArticleBody =
                  finalArticle.articleData[0]?.ArticleBody.slice(
                    location.join(" ")?.length
                  );
              }
            }

            setArticleViewData({
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }
          setTimeout(() => {
            updateInsight("view", {
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }, 1200);
        }
        return finalArticle;
      }

      return { photographs, articleData: [] };
    } catch (error) {}
  };
  const findLocation = (data) => {
    const iterated = [];
    if (data && data.length > 0) {
      let split = data.split(" ").slice(0, 4);

      for (let i = 0; i < split.length; i++) {
        const ele = split[i];

        if (ele.search(":") !== -1) {
          split[i] = ele.split(":")[0] + ":";
          for (let s = 0; s < i + 1; s++) {
            const element = split[s];
            iterated.push(element);
          }
        } else {
        }
      }
    }
    return iterated;
  };
  // const searchLink = async (path, zone) => {
  //   let response = [];

  //   if (linkJsonData?.linkJson?.length > 0) {
  //     response = linkJsonData?.linkJson.filter(
  //       (v) => v.FromZoneID === zone.ZoneId
  //     );
  //   }

  //   return response;
  // };

  const [currentPublication, setCurrentPublication] = useState({});

  GPTAdsManager.initializeAds(false, true);
  useEffect(() => {
    setTimeout(() => {
      window?.googletag.pubads().refresh();
    }, 500); // forces Ad reloading
  });
  // Usage example:

  // somewhere in the code...

  // uncomment below 3 lines to open GPT Ads console
  // window.googletag.cmd.push(() => {
  //   window.googletag.openConsole();
  // });
  function googleAds() {
    return (
      <div
      // className={desktopView && "mr-3"}
      // style={{
      //   float: desktopView ? "left" : "none",
      //   margin: desktopView ? "auto" : "0px 20px 0px 0px",
      // }}
      >
        <Ad
          path={`/1064661/Inside_Article_1st_Left`}
          width={300}
          height={250}
          divId={`div-gpt-ad-1640342494568-0`}
        />

        {/* <DFPSlotsProvider dfpNetworkId={"1064661/Inside_Article_1st_Left"}>
          <AdSlot sizes={[[300, 250]]} adUnit="test/refreshable" />
        </DFPSlotsProvider> */}
      </div>
    );
  }
  const [loading, setArticleLoading] = useState(true);

  // global state

  const [speechRunning, setSpeechRunning] = useState(false);
  const [Player, setPlayer] = useState(null);
  const [sharingUrl, setSharingUrl] = useState("");
  useEffect(() => {
    if (articleViewData?.Information) {
      setHasInfoZone(true);
    }
  }, [articleViewData]);

  const updateInsight = async (type, data, share_type) => {
    let name_split = await data?.ArticleName?.split("_");
    let post_url = `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/insight`;
    if (name_split && name_split.length > 0) {
      let data_object = {
        type,
        articlename: data?.ArticleName,
        articletitle: data?.ArticleTitle,
        edition_code: name_split?.[5], //edition_code,
        edition_date: `${name_split?.[2]}-${name_split?.[1]}-${name_split?.[0]}`, //edition_date,
      };
      if (type === "share") {
        data_object.share_type = share_type;
      }
      axios
        .post(post_url, data_object, {
          headers: {
            token: JSCookie.get("andre"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (type === "view") {
            setViews(res.data.view_count);
          } else {
            let shareCount = {
              ...shares,
              [share_type]: res.data.share_count[share_type],
            };
            setShares(shareCount);
            let totalShareCount =
              shareCount["email"] ??
              0 + shareCount["facebook"] ??
              0 + shareCount["whatsapp"] ??
              0 + shareCount["twitter"] ??
              0;
            setTotalShareCount(totalShareCount ?? 0);
          }
        })
        .catch((err) => {});
    }
  };
  useEffect(() => {
    const generateTTS = async () => {
      let text_to_speech = "";
      if (articleViewData && Object.keys(articleViewData).length > 0) {
        text_to_speech = articleViewData?.tts;
        if (relatedArticleData?.length > 0) {
          for (let i = 0; i < relatedArticleData.length; i++) {
            const ele = relatedArticleData[i];
            text_to_speech += ele.tts;
          }
        }
        if (
          articleViewDataLinked &&
          Object.keys(articleViewDataLinked).length > 0
        ) {
          text_to_speech += articleViewDataLinked.tts;
        }
        setTTS(text_to_speech);
      }
    };
    generateTTS();
    return () => {
      setSpeechRunning(false);
      Player?.pause();
      setPlayer(null);
    };
  }, [articleViewData, articleViewDataLinked, relatedArticleData]);

  async function synthesizeSpeech(text_to_speech) {
    let responsiveVoice = window.responsiveVoice;
    if (responsiveVoice.isPlaying()) {
      // console.log("I hope you are listening");
    } else {
      // console.log("I hope its not playing");
    }

    if (speechRunning && Player) {
      // console.log("should pause");
      return responsiveVoice.pause();
    }
    if (!speechRunning && Player) {
      // console.log("should resume");
      return responsiveVoice.resume();
    }
    if (!Player && text_to_speech) {
      setPlayer(responsiveVoice);
      responsiveVoice.speak(text_to_speech);
    }
  }
  const drawerWidth = 310;
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      // flexShrink: 0,
    },
    drawerPaper: {
      marginTop: 75,
      overflow: "auto",
      height: window.innerHeight - 110,
      // top: 100,
      width: drawerWidth,
      borderRight: "none",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [openArticleAd, setOpenArticleAd] = React.useState(false);
  const handleDrawerAd = () => {
    setOpenArticleAd(!openArticleAd);
  };

  // useEffect(() => {
  //   let currentPub = publicationJson[JSCookie.get("mtpubindex")];
  //   if (currentPub && Object.keys(currentPub).length > 0) {
  //     // if (currentPublication.type === "text") {
  //     //   setArticleTextView(true);
  //     // } else {
  //     //   setArticleTextView(false);
  //     // }
  //     setCurrentPublication(currentPub);
  //   }
  // }, []);
  const [ad, setAd] = useState({});

  const [isSecondPageFit2page, setIsSecondPageFit2page] = useState(false);
  const advertisement = useRef("");
  useEffect(() => {
    let adv = ad?.ZoneId?.split("_");
    if (adv && adv.length > 0) {
      adv.pop();
      advertisement.current = adv.join("_");
      setArticleViewName("");
    } else {
      advertisement.current = "";
    }
  }, [ad]);
  useEffect(() => {
    if (props.mostShared && props.mostShared.length > 0) {
      let findArticle = props.mostShared.filter(
        (v) => v.articlename === articleViewData?.ArticleName
      );
      if (findArticle?.length > 0 && findArticle[0].share_count) {
        let totalShareCount =
          findArticle[0].share_count?.["email"] ??
          0 + findArticle[0].share_count?.["facebook"] ??
          0 + findArticle[0].share_count?.["whatsapp"] ??
          0 + findArticle[0].share_count?.["twitter"] ??
          0;
        setTotalShareCount(
          totalShareCount && totalShareCount !== "NaN" ? totalShareCount : 0
        );
      }
    }
  }, [articleViewData, props]);
  const articleAuthorEmail = useRef(false);
  function saveImg() {
    // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
    if (ad.length !== 0) {
      FileSaver.saveAs(
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/Advertisement/${
          advertisement.current?.split("_")[3]
        }/${advertisement.current}_${initialEdition?.editionid}.jpg?v=${
          filteredDate?.Timestamp
        }`,
        "advertisement.jpg"
      );
    } else {
      FileSaver.saveAs(
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/Article/${
          articleViewName?.split("_")[3]
        }/${articleViewData?.ArticleName ?? articleViewName}.jpg?v=${
          filteredDate?.Timestamp
        }`,
        `${
          articleViewData?.ArticleTitle
            ? articleViewData?.ArticleTitle
            : "article"
        }.jpg?v=${new Date().getTime()}`
      );
    }
  }

  const extractEmail = (author) => {
    if (!author) return false;

    let matches = author?.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    );
    if (matches && matches.length) {
      return matches[0];
    } else {
      return "";
    }
  };

  useEffect(() => {
    setArticleLoading(true);

    if (articleViewData?.Author) {
      if (extractEmail(articleViewData?.Author)) {
        articleAuthorEmail.current = true;
      } else {
        articleAuthorEmail.current = false;
      }
    }
    setTimeout(() => {
      setArticleLoading(false);
    }, 500);
  }, [articleViewData]);

  useEffect(() => {
    if (hasInfoZone) {
      setArticleTextView(false);
    }
  }, [hasInfoZone]);

  let desktopView = window.innerWidth > 700;
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [articleTextView, setArticleTextView] = useState(true);
  const toggleShare = () => setIsOpenMobArticle(!isOpenMobArticle);
  const [articleBlurbFontsize, setArticleBlurbFontSize] = useState(
    JSCookie.get("articleBlurbFontsize")
      ? Number(JSCookie.get("articleBlurbFontsize"))
      : 13
  );
  const [articleBodyFontsize, setArticleBodyFontSize] = useState(
    JSCookie.get("articleBodyFontsize")
      ? Number(JSCookie.get("articleBodyFontsize"))
      : 17
  );
  const [articleHeadingFontsize, setArticleHeadingFontSize] = useState(
    JSCookie.get("articleHeadingFontsize")
      ? Number(JSCookie.get("articleHeadingFontsize"))
      : 43
  );
  const [articleHeadingMobFontsize, setArticleheadingMobFontSize] = useState(
    JSCookie.get("articleHeadingMobFontsize")
      ? Number(JSCookie.get("articleHeadingMobFontsize"))
      : 25
  );
  const [imgWidth, setImgWidth] = useState(80);
  const [imageDimension, setImageDimension] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });
  //article share
  const [allData, setAllData] = useState("");

  const imageSizeRef = useRef(1);
  const imageViewRef = useRef(1);
  const imageViewLinkRef = useRef(1);

  const photographRef = useRef(1);

  const [imageSizes, setImageSizes] = useState({
    width: 1,
    height: 1,
  });
  const [imagesViewSize, setImageViewSize] = useState({
    width: 1,
    height: "auto",
  });
  const [imagesViewLinkSize, setImageViewLinkSize] = useState({
    width: 1,
    height: "auto",
  });
  const [photographSize, setPhotographSize] = useState({
    width: 1,
    height: "auto",
  });

  const [textData, setTextData] = useState([""]);

  const pageNumber = useMemo(() => {
    let currentPageNumber = props?.currentPage?.PageName?.slice(11, 14);

    return currentPageNumber;
  }, [
    props.isSecondPageFit2page,
    props.twoPageImage1Name,
    props.twoPageImage2Name,
    props.currentPage,
  ]);
  const increaseFontSize = () => {
    JSCookie.set("articleBlurbFontsize", articleBlurbFontsize + 2);
    JSCookie.set("articleBodyFontsize", articleBodyFontsize + 2);
    JSCookie.set("articleHeadingFontsize", articleHeadingFontsize + 2);
    JSCookie.set("articleHeadingMobFontsize", articleHeadingMobFontsize + 2);

    setArticleBlurbFontSize(articleBlurbFontsize + 2);
    setArticleBodyFontSize(articleBodyFontsize + 2);
    setArticleHeadingFontSize(articleHeadingFontsize + 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize + 2);
  };

  const decreaseFontSize = () => {
    JSCookie.set("articleBlurbFontsize", articleBlurbFontsize - 2);
    JSCookie.set("articleBodyFontsize", articleBodyFontsize - 2);
    JSCookie.set("articleHeadingFontsize", articleHeadingFontsize - 2);
    JSCookie.set("articleHeadingMobFontsize", articleHeadingMobFontsize - 2);

    setArticleBlurbFontSize(articleBlurbFontsize - 2);
    setArticleBodyFontSize(articleBodyFontsize - 2);
    setArticleHeadingFontSize(articleHeadingFontsize - 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize - 2);
  };

  useEffect(() => {
    setArticleLoading(false);
    //   setImgWidth(80);
    //   setImageViewSize({
    //     width: imageViewRef.current.naturalWidth,
    //     height: "auto",
    //   });
  }, []);

  useEffect(() => {
    setImgWidth(80);
    setImageViewSize({
      width: imageViewRef?.current?.naturalWidth - 50,
      height: "auto",
    });
    if (articleViewDataLinked) {
      setImageViewLinkSize({
        width: imageViewLinkRef?.current?.naturalWidth - 50,
        height: "auto",
      });
    }
  }, [imageViewRef, imageViewLinkRef, articleViewDataLinked]);

  const zoomImageSize = (zoomBy, zoomImgView) => {
    setImgWidth((currState) => {
      const newWidth = currState + zoomBy;
      return newWidth;
    });

    setImageViewSize({ width: imagesViewSize.width + zoomImgView });
    if (articleViewDataLinked) {
      setImageViewLinkSize({ width: imagesViewLinkSize.width + zoomBy });
    }
  };
  const toggleView = () => {
    setArticleTextView(!articleTextView);
  };

  const parsedBody = useRef(articleViewData?.ArticleBody);
  const parsedLinkedBody = useRef(articleViewDataLinked?.ArticleBody);

  useEffect(() => {
    if (
      articleViewData &&
      articleViewData.ArticleBody &&
      articleViewData.ArticleBody?.length > 0
    ) {
      const htmlparser = (str) => {
        var input_str; //store input
        var text_input; //store input after beging trim()med
        var output_html = ""; //store output
        var counter;
        input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str

        //remove duplicate location
        if (articleViewData?.ArticleLocation) {
          let regex = new RegExp("^" + articleViewData?.ArticleLocation, "i");
          if (input_str.match(regex)) {
            let val = input_str.match(regex);
            let len = val.index + articleViewData?.ArticleLocation.length;
            input_str = input_str.slice(len);
          }
        }

        text_input = input_str.trim(); //trim() input
        if (text_input.length > 0) {
          output_html += `<p style='font-family:LibreBaskerville !important;'>`; //begin by creating paragraph
          let foundFullStop = false;
          let sentences = [];
          for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
              case "\n":
                if (text_input[counter + 1] === "\n") {
                  output_html += "</p>\n<p>";
                  counter++;
                } else {
                  output_html += "<br/>";
                }
                break;
              case ".":
                // if (sentences.length === 0) {
                //   foundFullStop = true;
                //   output_html += ".<br/>";
                // } else {
                output_html += ". ";
                // }
                break;
              case " ":
                if (
                  text_input[counter - 1] !== " " &&
                  text_input[counter - 1] !== "\t"
                )
                  output_html += " ";
                break;

              case "\t":
                if (text_input[counter - 1] !== "\t") output_html += " ";
                break;

              case "":
                output_html += "›";
                break;
              case "`":
                output_html += "₹";
                break;

              default:
                output_html += text_input[counter];
            }
            if (foundFullStop) {
              if (sentences.length === 0) {
                sentences.push(output_html.split("<br/>"));
                let style = `float:left;margin-right:20px;`;
                if (!desktopView) {
                  style = `margin:5px auto 15px;`;
                }

                output_html += `googleAds`;
              }
            }
          }

          output_html += "</p>"; //finally close paragraph
          output_html = output_html.split("br_tag").join("<br/>");
          output_html = output_html.split("&lt;").join("<");
          output_html = output_html.split("&gt;").join(">");
          output_html = output_html.split("").join("›");
          output_html = output_html.split("googleAdDivHere").join("googleAds");
        }

        if (props.isSearch?.search) {
          output_html = output_html
            .split(props.isSearch?.search.toLowerCase())
            .join(
              `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
            );

          let text = `<span style='line-height:${
            Number(articleBodyFontsize) + 10
          }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
            .charAt(0)
            .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
          output_html = output_html
            .split(
              props.isSearch?.search.charAt(0).toUpperCase() +
                props.isSearch?.search.slice(1)
            )
            .join(text);
        }

        return output_html;
      };
      parsedBody.current = htmlparser(articleViewData?.ArticleBody);

      findLink(articleViewData);
      // htmlparser2(articleViewData?.ArticleBody);
      // } else {
      //   parsedBody.current = articleViewData?.ArticleBody;
      // }
    } else {
      parsedBody.current = "<p></p>";
    }
    if (
      props.isSearch?.from === "photographs" ||
      props.isSearch?.from === "advertisements"
    )
      setArticleTextView(false);
    if (props?.isSearch?.from !== "advertisements" && !ad) {
      advertisement.current = "";
    }
  }, [props.isSearch, articleViewData]);
  useEffect(() => {
    if (
      articleViewDataLinked &&
      articleViewDataLinked.ArticleBody &&
      articleViewDataLinked.ArticleBody?.length > 0
    ) {
      const htmlparser = (str) => {
        var input_str; //store input
        var text_input; //store input after beging trim()med
        var output_html = ""; //store output
        var counter;
        // input_str = str ? str?.split("<br>").join(" ").split("&lt;br&gt;&lt;br&gt;").join("<br/><br/>").split("&lt;p&gt;").join("<p>").split("&lt;/p&gt;").join("</p>").split("&lt;b&gt;").join("<b>").split("&lt;/b&gt;").join("</b>") : ""; //get input and store it in input_str
        input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str

        text_input = input_str.trim(); //trim() input
        if (text_input.length > 0) {
          output_html += `<p style='font-family:LibreBaskerville !important;'>`; //begin by creating paragraph

          for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
              case "\n":
                if (text_input[counter + 1] === "\n") {
                  output_html += "</p>\n<p>";
                  counter++;
                } else {
                  output_html += "<br/>";
                }
                break;

              case " ":
                if (
                  text_input[counter - 1] !== " " &&
                  text_input[counter - 1] !== "\t"
                )
                  output_html += " ";
                break;

              case "\t":
                if (text_input[counter - 1] !== "\t") output_html += " ";
                break;

              case "`":
                output_html += "₹";
                break;

              case "":
                output_html += "›";
                break;
              default:
                output_html += text_input[counter];
            }
          }

          output_html += "</p>"; //finally close paragraph
          output_html = output_html.split("br_tag").join("<br/>");
          output_html = output_html.split("&lt;").join("<");
          output_html = output_html.split("&gt;").join(">");
          output_html = output_html.split("").join("›");
        }
        if (props.isSearch?.search) {
          output_html = output_html
            .split(props.isSearch?.search.toLowerCase())
            .join(
              `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
            );
          let text = `<span style='line-height:${
            Number(articleBodyFontsize) + 10
          }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
            .charAt(0)
            .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
          output_html = output_html
            .split(
              props.isSearch?.search.charAt(0).toUpperCase() +
                props.isSearch?.search.slice(1)
            )
            .join(text);
        }
        return output_html;
      };
      // if (props.isSearch?.search) {

      parsedLinkedBody.current = htmlparser(articleViewDataLinked?.ArticleBody);
    }
  }, [articleViewDataLinked]);
  useEffect(() => {
    if (articleViewData == null) {
      setAd({
        ZoneId: props.location.search
          .split("?article=")?.[1]
          .split("_")
          .slice(0, 6)
          .join("_"),
      });
    } else setAd({});
  }, [articleViewData]);
  const getOpacity = (article, searchQuery) => {
    if (article.BlockType === "WORD") {
      const searchWords = searchQuery?.toLowerCase();

      if (article.Text) {
        if (searchWords?.length > article?.Text?.length) {
          if (searchWords?.includes(article?.Text?.toLowerCase())) {
            return 0.7;
          } else return 0;
        } else {
          if (
            article?.Text?.toLowerCase().includes(searchWords?.toLowerCase())
          ) {
            return 0.7;
          } else return 0;
        }
      }
    } else {
      return 0;
    }
  };
  // useEffect(() => {
  //   if (
  //     imageUrl &&
  //     (activeTabName === "Photographs" || activeTabName === "Advertisement")
  //   ) {
  //     axios.get(imageUrl).then((response) => {
  //       setTextData(response.data);
  //       const fullImageDimension = response.data[0].Geometry.BoundingBox;
  //       setImageDimension({
  //         height: (fullImageDimension.Height * imageSizes.height) / 2,
  //         width: (fullImageDimension.Width * imageSizes.width) / 2,
  //         top: (fullImageDimension.Top * imageSizes.height) / 2,
  //         left: (fullImageDimension.Left * imageSizes.width) / 2,
  //       });
  //     });
  //   }
  // }, [imageUrl, imageSizes, activeTabName]);

  useEffect(() => {
    if (initialPublication?.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [initialPublication]);

  // useEffect(() => {
  let toReplace = [];
  const searchLink = async (path, zone) => {
    let response = [];

    if (linkJsonData?.linkJson?.length > 0) {
      response = linkJsonData?.linkJson.filter(
        (v) => v.FromZoneID === zone?.ZoneId
      );
    }

    return response;
  };
  const findLink = async (data) => {
    let linked_zones = [];

    if (data && Object.keys(data).length > 0) {
      const zones = articles?.PageContent?.filter(
        (v) => v.ArticleName === data.ArticleName
      );
      const linkToZones = zones?.[0]?.Zones?.filter(
        (v) => v.TagName === "LinkTo"
      );

      if (linkToZones?.length > 0) {
        for (let i = 0; i < linkToZones.length; i++) {
          const ele = linkToZones[i];
          let filtered_response = await searchLink("", ele);

          if (filtered_response && filtered_response.length > 0) {
            linked_zones.push({
              zoneId: ele.ZoneId,
              pageLink: filtered_response[0].PageLink,
            });
          }
        }
        setTimeout(() => {
          const myFunc = (e) => {
            let jumpTo = props.dayIndexData.findIndex(
              (v) => v.PageName === e.target.className
            );
            let link = `${s3Source}/PublicationData/${
              initialPublication?.publicationcode
            }/${initialEdition?.editionid}/${dateArray[0]}/${dateArray[1]}/${
              dateArray[2]
            }/PageThumb/${e.target?.className}.jpg?v=${new Date().getTime()}`;
            if (jumpTo !== -1) {
              // pageClick(link, jumpTo);
            }
            props.toggleArticleViewModal();
          };

          for (let i = 0; i < linked_zones.length; i++) {
            const element = linked_zones[i];
            let domElement = document.getElementById(`${element.zoneId}`);

            if (domElement) {
              domElement.addEventListener("click", myFunc);
            }
          }
        }, 800);
        // } // check
      }
    }
  };

  useEffect(() => {
    setAllData(articleViewData);
  }, [props?.toggleArticleViewModal]);
  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";

      displayToastr("Copied successfully", "Copied", 5000, "success");
    } catch (err) {
      displayToastr("Could not copy", "Copy Link", 5000, "error");
    }

    document.body.removeChild(textArea);
  }

  const shareArticle = async (shareType) => {
    let path = "";
    // http://test.timesgroup.com/article-share?article=https://asset.harnscloud.com/PublicationData/TOI/toiac/2022/01/20/ArticleZoneJson/019/20_01_2022_019_010_toiac.json
    // let path_prefix = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/ArticleZoneJson/`;

    // let searched = await getContinuedArticles(
    //   linkJsonPath ??
    //     `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/LinkJson/${dateArray[2]}_${dateArray[1]}_${dateArray[0]}_${initialEdition?.editionid}.json`,
    //   articleName,
    //   path_prefix
    // );
    // let articleName = allData?.ArticleName;
    let articleName =
      articleViewData?.ArticleName ??
      `${advertisement.current}_${initialEdition?.editionid}`;
    let pageNumber = articleName?.split("_")?.[3];

    // path = `${path_prefix}${pageNumber}/${articleName}.json`;
    path = `${articleName}`;

    switch (shareType) {
      case "copy":
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(
            `${window.location.origin}/maharashtratimes/article-share?article=${path}`
          );
          return;
        }
        navigator.clipboard.writeText(
          `${window.location.origin}/maharashtratimes/article-share?article=${path}`
        );
        displayToastr("Copied successfully", "Copied", 5000, "success");
        break;

      default:
        break;
    }
  };

  const pathForShare = useMemo(() => {
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${initialPublication.publicationcode}/${initialEdition.editionid}/${dateArray[0]}/${dateArray[1]}/${dateArray[2]}/ArticleZoneJson/`;

    // let articleName = allData && allData.ArticleName;
    let articleName =
      articleViewData?.ArticleName ??
      `${advertisement.current}_${initialEdition?.editionid}`;
    let pageNumber = articleName?.split("_")[3];
    // path = `${path_prefix}${pageNumber}/${articleName}.json`;
    path = `${articleName}`;
    setSharingUrl(
      `${window.location.origin}/maharashtratimes/article-share?article=${path}`
    );

    return `${window.location.origin}/maharashtratimes/article-share?article=${path}`;
  }, [
    initialPublication?.publicationcode,
    initialEdition?.editionid,
    dateArray,
    allData,
  ]);
  const removeJunk = (str) => {
    return str
      ?.split("<br>`<br>")
      .join("₹")
      .split("<br>`")
      .join("₹")
      .split("`<br>")
      .join("₹")
      .split("`")
      .join("₹")
      .split("<br>¤<br>")
      .join("€")
      .split("<br>¤")
      .join("€")
      .split("¤<br>")
      .join("€")
      .split("¤")
      .join("€")
      ?.split("<br>")
      ?.join("<br/>")
      .split("&lt")
      .join("<")
      .split("&gt;")
      .join(">")
      .split("")
      .join("›");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Maharashtra Times ${initialEdition?.editionname} epaper: Read Maharashtra Times ${initialEdition?.editionname} Online Hindi Epaper | ${initialEdition?.editionname} नवभारत टाइम्स ई-पेपर | ${initialEdition?.editionname} Hindi Newspaper Online
`}</title>
        <link rel="canonical" href={window.href} />

        <meta
          name="description"
          content={`Maharashtra Times ${initialEdition?.editionname} ePaper: Read your Latest, Daily ${initialEdition?.editionname} ePaper News on Politics, Crime, Sports in Hindi, Entertainment updates and latest breaking news of ${initialEdition?.editionname} | Maharashtra Times Newspaper Online`}
        />
        <meta
          name="title"
          content={`Maharashtra Times ${initialEdition?.editionname} epaper: Read Maharashtra Times ${initialEdition?.editionname} Online Hindi Epaper | ${initialEdition?.editionname} नवभारत टाइम्स ई-पेपर | ${initialEdition?.editionname} Hindi Newspaper Online
`}
        />

        <meta content={`The Times Group`} name="author" />
        <meta content={`The Times Group`} name="Publisher" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:published_time"
        />
        <meta
          property="og:site_name"
          content={`${initialPublication?.publicationcode} epaper`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:modified_time"
        />
        <meta
          property="og:title"
          content={`${initialPublication?.publicationname} epaper`}
        />
        <meta
          name="Keywords"
          content={`Maharashtra Times ${initialEdition?.editionname} Hindi ePaper, Maharashtra Times ${initialEdition?.editionname} online Hindi Epaper,${initialEdition?.editionname} नवभारत टाइम्स ई-पेपर, Maharashtra Times ${initialEdition?.editionname} Hindi News Paper, Maharashtra Times ${initialEdition?.editionname} Newspaper online, ${initialEdition?.editionname} Newspaper Online,Latest ${initialEdition?.editionname} Epaper, breaking news of ${initialEdition?.editionname}, Today's ${initialEdition?.editionname} news updates`}
        />

        <meta
          name="breadcrumbs"
          content={`Home>Maharashtra Times ${initialEdition?.editionname} E-Paper`}
        />
        <meta property="og:locale" content={`hi_IN`} />
        <meta property="og:image" content={`/${logo}`} />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />

        <meta content="summary_large_image" name="twitter:card" />
        <meta name="twitter:title" content={`Maharashtra times E-paper`} />
        <meta
          property="twitter:description"
          content={`Maharashtra Times ${initialEdition?.editionname} ePaper: Read your Latest, Daily ${initialEdition?.editionname} ePaper News on Politics, Crime, Sports in Hindi, Entertainment updates and latest breaking news of ${initialEdition?.editionname} | Maharashtra Times Newspaper Online`}
        />
        <meta
          name="twitter:url"
          content={`https://nbtepaper.harnscloud.com/`}
        />
        <meta
          name="twitter:domain"
          content={`https://nbtepaper.harnscloud.com/`}
        />
        <meta name="twitter:creator" content={`maharashtratimes`} />
        <meta name="twitter:site" content={`maharashtratimes`} />
      </Helmet>

      <Container fluid="true" className="fff ff4">
        {desktopView ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              props?.toggleArticleViewModal();
            }}
          >
            <div className="fff ff4">
              <ModalHeader
                className="pb-1 "
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1000",
                  backgroundColor: "#fff",
                  display: "block",
                  border: "0px !important",
                }}
              >
                <Row className="d-flex align-items-center">
                  <Col>
                    <Link to={"/maharashtratimes/home"}>
                      <img
                        style={{ maxHeight: "80px" }}
                        src="/maharashtratimes-assets/logo/mt.png"
                        alt="timesgroup"
                      />
                    </Link>
                  </Col>
                  <div className="col-md-10 d-flex align-items-center justify-content-center">
                    <Col md="1" className="mt-3">
                      {!ad?.ZoneId && (
                        <>
                          <Tooltip title="All Views">
                            <div
                              className="badge p-1 text-white mb-4"
                              style={{
                                backgroundColor: "var(--primary)",
                              }}
                            >
                              Views: <span>{views}</span>
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </Col>

                    <Col sm="6" md="4" lg="4" xl="4" className="mx-2">
                      <Row className="justify-content-center pb-2 header-article-fixed  ">
                        <Row>
                          {currentPublication?.type !== "image" &&
                          !isRegional &&
                          !ad?.ZoneId &&
                          !hasInfoZone ? (
                            <Col
                              style={{
                                color: themeColor,
                              }}
                              onClick={() => {
                                toggleView();
                              }}
                              className=" cursor-pointer2"
                            >
                              {articleTextView ? (
                                <div className="pt-2">
                                  <Tooltip title="Image">
                                    <Image />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="pt-2">
                                  <Tooltip title="Text">
                                    <FileText />
                                  </Tooltip>
                                </div>
                              )}
                            </Col>
                          ) : (
                            ""
                          )}
                          <Col
                            className="cursor-pointer2 pl-3, pt-2 "
                            style={{ color: themeColor }}
                            onClick={() => {
                              articleTextView && increaseFontSize();
                              zoomImageSize(30, 90);
                            }}
                          >
                            <Tooltip title="Zoom In">
                              <ZoomIn />
                            </Tooltip>
                          </Col>
                          <Col
                            className="cursor-pointer2 , pt-2"
                            style={{ color: themeColor }}
                            onClick={() => {
                              articleTextView && decreaseFontSize();
                              zoomImageSize(-30, -90);
                            }}
                          >
                            <Tooltip title="Zoom Out">
                              <ZoomOut />
                            </Tooltip>
                          </Col>

                          {/* for future use */}
                          {/* {currentPublication?.type !== "image" &&
                            !isRegional &&
                            !ad?.ZoneId && (
                              <Col
                                className="pt-2 cursor-pointer2"
                                style={{
                                  color: themeColor,
                                }}
                                onClick={() => {
                                  setSpeechRunning(!speechRunning);
                                  synthesizeSpeech(TTS);
                                }}
                              >
                                {!speechRunning ? <VolumeX /> : <Volume2 />}
                              </Col>
                            )} */}

                          <Col
                            className="pt-2 cursor-pointer2"
                            style={{ color: themeColor }}
                            onClick={() => print("article-view-card")}
                          >
                            <Tooltip title="Print">
                              <PrintIcon />
                            </Tooltip>
                          </Col>
                          {/* <Col
                            style={{ color: themeColor }}
                            className="pt-2 cursor-pointer2"
                            onClick={toggle}
                          >
                            <Tooltip title="Share">
                              <Share2 />
                            </Tooltip>
                          </Col> */}
                        </Row>
                      </Row>
                    </Col>
                    <Col md="1" className="mt-3">
                      {!ad?.ZoneId && (
                        <>
                          <Tooltip title="All Shares">
                            <div
                              className="badge p-1 text-white mb-4 ml-2"
                              style={{
                                backgroundColor: "var(--primary)",
                              }}
                            >
                              Shares: <span>{totalShareCount}</span>
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </Col>
                  </div>
                  <Col></Col>
                </Row>
              </ModalHeader>

              <Row
                className=""
                id={articleTextView ? "article-scroll-container" : ""}
                style={{
                  height: window.innerHeight - 110,
                  width: articleTextView ? "98%" : "auto",
                }}
              >
                <Col md="12">
                  <Row className="pr-3 justify-content-start">
                    {/* for future use if we need left ad with drawer */}

                    <Col md="12" className="article-view-container">
                      <Row className="justify-content-center mt-2">
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setIsOpen2(false);
                          }}
                        >
                          <Collapse isOpen={isOpen2}>
                            <Row className="pl-0 collapse-fixed-desktop">
                              <Card className="social-share-desktop">
                                <CardBody>
                                  <Row
                                    className="cursor-pointer"
                                    style={{ justifyContent: "space-around" }}
                                  >
                                    <WhatsappShareButton
                                      title={
                                        articleViewData &&
                                        removeJunk(
                                          articleViewData?.ArticleTitle
                                        )
                                      }
                                      url={sharingUrl && sharingUrl}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "whatsapp"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Whatsapp share">
                                        <WhatsAppIcon
                                          fontSize="medium"
                                          style={{ color: "#25D366" }}
                                        />
                                      </Tooltip>
                                    </WhatsappShareButton>

                                    <FacebookShareButton
                                      quote={
                                        articleViewData &&
                                        removeJunk(
                                          articleViewData?.ArticleTitle
                                        )
                                      }
                                      url={sharingUrl && sharingUrl}
                                      beforeOnClick={() => {}}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "facebook"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Facebook share">
                                        <FacebookIcon
                                          fontSize="medium"
                                          style={{ color: "#4267B2" }}
                                        />
                                      </Tooltip>
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                      title={
                                        articleViewData &&
                                        removeJunk(
                                          articleViewData?.ArticleTitle
                                        )
                                      }
                                      url={pathForShare}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "twitter"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Twitter share">
                                        <TwitterIcon
                                          fontSize="medium"
                                          style={{ color: "#1DA1F2" }}
                                        />
                                      </Tooltip>
                                    </TwitterShareButton>
                                    <EmailShareButton
                                      className="pb-0"
                                      subject={
                                        articleViewData &&
                                        removeJunk(
                                          articleViewData?.ArticleTitle
                                        )
                                      }
                                      url={pathForShare}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "email"
                                        );
                                      }}
                                    >
                                      <Tooltip title="E-mail share">
                                        <Mail
                                          fontSize="small"
                                          style={{ color: themeColor }}
                                        />
                                      </Tooltip>
                                    </EmailShareButton>
                                    <Tooltip title="Copy Link">
                                      <Copy
                                        onClick={() => shareArticle("copy")}
                                        fontSize="small"
                                        style={{ color: themeColor }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Close">
                                      <X
                                        color="red"
                                        onClick={() => {
                                          setIsOpen2(!isOpen2);
                                        }}
                                      />
                                    </Tooltip>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Row>
                          </Collapse>
                        </OutsideClickHandler>
                      </Row>
                      <Card id="article-view-card" className="border-0 ">
                        {loading ? (
                          <Spinner className="loaderArticleCard" />
                        ) : articleTextView && !isRegional ? (
                          <div className="article-view-desktop">
                            {articleViewData?.Blurb && (
                              <div
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  width: "fit-content",
                                }}
                              >
                                <h6
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                  }}
                                  id="article-card-blurb"
                                  className="p-1"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      removeJunk(articleViewData?.Blurb),
                                  }}
                                ></h6>
                              </div>
                            )}
                            <div>
                              <h1
                                id="article-card-title"
                                style={{
                                  fontWeight: "700",
                                  fontSize: `${articleHeadingFontsize}px`,
                                  fontFamily: "fff ff4 !important",
                                  lineHeight: `${
                                    articleHeadingFontsize + 18
                                  }px`,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    removeJunk(articleViewData?.ArticleTitle),
                                }}
                              ></h1>
                              {articleViewData?.ColumnTitle && (
                                <h6
                                  style={{
                                    fontSize: `${
                                      Number(articleBlurbFontsize) + 10
                                    }px`,
                                    fontWeight: "normal",
                                    fontStyle: "italic",
                                    color: "#4a4949",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      removeJunk(articleViewData?.ColumnTitle),
                                  }}
                                ></h6>
                              )}
                              {articleViewData && (
                                <hr style={{ borderTop: "2px solid black" }} />
                              )}
                              {articleViewData?.Author && (
                                <b
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                    fontStyle: "italic",
                                    color: articleAuthorEmail.current
                                      ? "#0000ee"
                                      : "#000",
                                    textDecoration: articleAuthorEmail.current
                                      ? "underline"
                                      : "none",
                                    fontWeight: articleAuthorEmail.current
                                      ? "bold"
                                      : "normal",
                                    fontFamily: "LibreBaskerville",
                                    cursor: articleAuthorEmail.current
                                      ? "pointer"
                                      : "auto",
                                  }}
                                  onClick={() => {
                                    if (articleAuthorEmail.current) {
                                      window.open(
                                        "mailto:" +
                                          extractEmail(articleViewData.Author),
                                        "mail"
                                      );
                                    }
                                  }}
                                  id="article-card-author"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.Author &&
                                      extractEmail(articleViewData.Author),
                                  }}
                                ></b>
                              )}
                              {articleViewData?.Author &&
                                articleAuthorEmail.current === false && (
                                  <b
                                    style={{}}
                                    id="article-card-author"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData?.Author ===
                                          "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? "TIMES NEWS NETWORK"
                                          : articleViewData.Author,
                                    }}
                                  ></b>
                                )}
                              {articleViewData?.ArticleEmail && (
                                <a
                                  href={
                                    "mailto:" + articleViewData?.ArticleEmail
                                  }
                                >
                                  <i
                                    style={{
                                      fontSize: `${
                                        Number(articleBodyFontsize) - 4
                                      }px`,
                                      color: "#0000ee",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                      fontFamily: "LibreBaskerville",
                                    }}
                                    id="article-card-mail"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData?.ArticleEmail,
                                    }}
                                  ></i>
                                </a>
                              )}
                              {articleViewData &&
                                articleViewData?.ArticleSource && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                      color: "#4a4949",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData.ArticleSource ===
                                          "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? "TIMES NEWS NETWORK"
                                          : articleViewData?.Author?.split(
                                              "<br>"
                                            )
                                              ?.join(" ")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                    }}
                                  ></b>
                                )}
                              {articleViewData &&
                                articleViewData?.ArticleLocation && (
                                  <p>
                                    <br />
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        fontWeight: "bold",
                                        color: "#4a4949",
                                        fontFamily: "LibreBaskerville",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewData &&
                                          articleViewData?.ArticleLocation,
                                      }}
                                    ></b>
                                  </p>
                                )}
                              {/* <div className="ad-wrapper">
                              <CardImg src={article_ad} />
                            </div> */}
                              <p
                                style={{
                                  fontSize: `${articleBodyFontsize + 1}px `,
                                  lineHeight: `${
                                    Number(articleBodyFontsize) + 15
                                  }px`,
                                  color: "#4a4949",
                                }}
                                id="articlecardpara"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    removeJunk(parsedBody.current)?.split(
                                      "googleAds"
                                    )?.[0],
                                }}
                                className="mt-2 mb-2"
                              />
                              {articleViewData &&
                                Object.keys(articleViewData)?.length > 0 && (
                                  <div
                                    style={{
                                      width: 300,
                                      height: 250,

                                      float: desktopView ? "left" : "none",
                                      margin: desktopView
                                        ? "auto"
                                        : "0px 20px 0px 0px",
                                    }}
                                    className={desktopView && "mr-3"}
                                  >
                                    {googleAds()}
                                  </div>
                                )}
                              <span
                                style={{
                                  fontSize: `${articleBodyFontsize + 1}px `,
                                  lineHeight: `${
                                    Number(articleBodyFontsize) + 15
                                  }px`,
                                  color: "#4a4949",
                                  fontFamily: "LibreBaskerville",
                                }}
                                id="articlecardpara"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    removeJunk(parsedBody.current)?.split(
                                      "googleAds"
                                    )?.[1],
                                }}
                                className="mt-2 mb-5"
                              >
                                {/* November 7, 2020 */}
                                {/* <br /> */}
                              </span>
                              {relatedArticleData &&
                                relatedArticleData?.length > 0 &&
                                relatedArticleData?.map((item, ind) => {
                                  return (
                                    <div
                                      className={"greyBox"}
                                      key={item?.ArticleTitle}
                                    >
                                      {item?.Blurb && (
                                        <div
                                          style={{
                                            backgroundColor: "red",
                                            color: "white",
                                            width: "fit-content",
                                          }}
                                        >
                                          <h6
                                            style={{
                                              fontSize: `${articleBlurbFontsize}px`,
                                            }}
                                            id="article-card-blurb"
                                            className="p-1"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item && removeJunk(item?.Blurb),
                                            }}
                                          ></h6>
                                        </div>
                                      )}
                                      <div>
                                        <h1
                                          id="article-card-title"
                                          style={{
                                            fontWeight: "700",
                                            fontSize: `${
                                              articleHeadingFontsize - 10
                                            }px`,
                                            fontFamily: "fff ff4 !important",
                                            lineHeight: `${
                                              articleHeadingFontsize + 10
                                            }px`,
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item &&
                                              removeJunk(item?.ArticleTitle),
                                          }}
                                        />
                                        {item?.ColumnTitle && (
                                          <h6
                                            style={{
                                              fontSize: `${
                                                Number(articleBlurbFontsize) +
                                                10
                                              }px`,
                                              fontWeight: "normal",
                                              fontStyle: "italic",
                                              color: "#4a4949",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item &&
                                                removeJunk(item?.ColumnTitle),
                                            }}
                                          ></h6>
                                        )}
                                        {item && (
                                          <hr
                                            style={{
                                              borderTop: "2px solid black",
                                            }}
                                          />
                                        )}

                                        {item && item?.ArticleLocation && (
                                          <b
                                            style={{
                                              fontSize: `${articleBodyFontsize}px`,
                                              fontWeight: "bold",
                                              color: "#4a4949",
                                              fontFamily: "LibreBaskerville",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item && item?.ArticleLocation,
                                            }}
                                          ></b>
                                        )}

                                        <p
                                          style={{
                                            fontSize: `${
                                              articleBodyFontsize + 1
                                            }px `,
                                            lineHeight: `${
                                              Number(articleBodyFontsize) + 15
                                            }px`,
                                            color: "#4a4949",
                                          }}
                                          id="articlecardpara"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item &&
                                              removeJunk(item?.ArticleBody)
                                                ?.split("googleAdDivHere")
                                                .join(" "),
                                          }}
                                          className="mt-2"
                                        >
                                          {/* November 7, 2020 */}
                                          {/* <br /> */}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* end */}
                              {/* linked articleViewData */}
                              {articleViewDataLinked &&
                                Object.keys(articleViewDataLinked).length >
                                  0 && (
                                  <div>
                                    <h1
                                      id="article-card-title"
                                      style={{
                                        fontWeight: "700",
                                        fontSize: `${articleHeadingFontsize}px`,
                                        fontFamily: "fff ff4 !important",
                                        lineHeight: `${
                                          articleHeadingFontsize + 18
                                        }px`,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleTitle?.split(
                                            "<br>`<br>"
                                          )
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">"),
                                      }}
                                    />

                                    {articleViewDataLinked?.ColumnTitle && (
                                      <h6
                                        style={{
                                          fontSize: `${
                                            Number(articleBlurbFontsize) + 10
                                          }px`,
                                          fontWeight: "normal",
                                          fontStyle: "italic",
                                          color: "#4a4949",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            articleViewDataLinked &&
                                            articleViewDataLinked?.ColumnTitle?.split(
                                              "<br>`<br>"
                                            )
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    )}
                                    {articleViewDataLinked && (
                                      <hr
                                        style={{ borderTop: "2px solid black" }}
                                      />
                                    )}

                                    {articleViewDataLinked &&
                                      articleViewDataLinked?.ArticleLocation && (
                                        <b
                                          style={{
                                            fontSize: `${articleBodyFontsize}px`,
                                            fontWeight: "bold",
                                            color: "#4a4949",
                                            fontFamily: "LibreBaskerville",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              articleViewDataLinked &&
                                              articleViewDataLinked?.ArticleLocation,
                                          }}
                                        ></b>
                                      )}

                                    <p
                                      style={{
                                        fontSize: `${
                                          articleBodyFontsize + 1
                                        }px `,
                                        lineHeight: `${
                                          Number(articleBodyFontsize) + 15
                                        }px`,
                                        color: "#4a4949",
                                      }}
                                      id="articlecardpara"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          parsedLinkedBody?.current
                                            ?.split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">")
                                            ?.split("googleAdDivHere")
                                            .join(" "),
                                      }}
                                      className="mt-2"
                                    >
                                      {/* November 7, 2020 */}
                                      {/* <br /> */}
                                    </p>
                                  </div>
                                )}
                              <Row
                                className=" mb-3"
                                style={{
                                  // width: "fit-content",
                                  // overflowX: "scroll",
                                  justifyContent: "left",
                                  marginTop:
                                    articleViewData?.ArticleBody?.length > 0
                                      ? "1rem"
                                      : "0",
                                }}
                              >
                                {articleViewData &&
                                articleViewData?.ArticlePhotographs &&
                                articleViewData?.ArticlePhotographs.length
                                  ? articleViewData?.ArticlePhotographs.map(
                                      (item, index) => {
                                        let photo =
                                          item?.Photograph?.split("_");
                                        return (
                                          <Col
                                            sm="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            key={
                                              item?.Photograph + index + "ll"
                                            }
                                          >
                                            <img
                                              onLoad={() => {
                                                setPhotographSize({
                                                  width:
                                                    photographRef.current
                                                      .naturalWidth,
                                                  height: "auto",
                                                });
                                              }}
                                              crossOrigin="*"
                                              ref={photographRef}
                                              src={
                                                photo[photo.length - 1] ===
                                                initialEdition?.editionid
                                                  ? `${s3Source}/PublicationData/${
                                                      initialPublication?.publicationcode
                                                    }/${
                                                      initialEdition?.editionid
                                                    }/${dateArray[0]}/${
                                                      dateArray[1]
                                                    }/${
                                                      dateArray[2]
                                                    }/Photographs/${
                                                      item?.Photograph?.split(
                                                        "_"
                                                      )[3]
                                                    }/${
                                                      item?.Photograph
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                                  : `${s3Source}/PublicationData/${
                                                      initialPublication?.publicationcode
                                                    }/${
                                                      initialEdition?.editionid
                                                    }/${dateArray[0]}/${
                                                      dateArray[1]
                                                    }/${
                                                      dateArray[2]
                                                    }/Photographs/${
                                                      item?.Photograph?.split(
                                                        "_"
                                                      )[3]
                                                    }/${item?.Photograph}_${
                                                      initialEdition?.editionid
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                              }
                                              alt={
                                                articleViewData?.ArticleTitle
                                              }
                                            />

                                            {item?.ImageCaption && (
                                              <p
                                                style={{
                                                  fontSize: `${articleBodyFontsize}px `,
                                                  width:
                                                    photographSize.width > 20
                                                      ? photographSize.width
                                                      : "auto",
                                                }}
                                                className="image-caption"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    item &&
                                                    removeJunk(
                                                      item?.ImageCaption
                                                    ),
                                                }}
                                              />
                                            )}
                                          </Col>
                                        );
                                      }
                                    )
                                  : ""}
                                {ad && advertisement?.current && (
                                  <div className="text-center">
                                    <img
                                      width={`${Number(imgWidth) + 30}%`}
                                      crossOrigin="*"
                                      className=""
                                      src={`${s3Source}/PublicationData/${
                                        initialPublication?.publicationcode
                                      }/${initialEdition?.editionid}/${
                                        dateArray[0]
                                      }/${dateArray[1]}/${
                                        dateArray[2]
                                      }/Advertisement/${
                                        advertisement.current?.split("_")[3]
                                      }/${advertisement.current}_${
                                        initialEdition?.editionid
                                      }.jpg?v=${new Date().getTime()}`}
                                      alt={articleViewData?.ArticleTitle}
                                    />
                                  </div>
                                )}
                              </Row>
                            </div>
                            {articleViewDataLinked &&
                              Object.keys(articleViewDataLinked).length > 0 && (
                                <Row
                                  className="justify-content-center mt-3"
                                  style={{
                                    width: "fit-content",
                                    overflow: "auto",
                                  }}
                                >
                                  {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticlePhotographs &&
                                  articleViewDataLinked?.ArticlePhotographs
                                    .length
                                    ? articleViewDataLinked?.ArticlePhotographs.map(
                                        (item, index) => {
                                          let photo =
                                            item?.Photograph?.split("_");
                                          return (
                                            <Col
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              xl="12"
                                              key={
                                                item?.Photograph + index + "ll"
                                              }
                                            >
                                              <img
                                                onLoad={() => {
                                                  setPhotographSize({
                                                    width:
                                                      photographRef?.current
                                                        ?.naturalWidth,
                                                    height: "auto",
                                                  });
                                                }}
                                                ref={photographRef}
                                                crossOrigin="*"
                                                className=""
                                                src={
                                                  photo[photo.length - 1] ===
                                                  initialEdition?.editionid
                                                    ? `${s3Source}/PublicationData/${
                                                        initialPublication?.publicationcode
                                                      }/${
                                                        initialEdition?.editionid
                                                      }/${dateArray[0]}/${
                                                        dateArray[1]
                                                      }/${
                                                        dateArray[2]
                                                      }/Photographs/${
                                                        item?.Photograph.split(
                                                          "_"
                                                        )[3]
                                                      }/${
                                                        item?.Photograph
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                    : `${s3Source}/PublicationData/${
                                                        initialPublication?.publicationcode
                                                      }/${
                                                        initialEdition?.editionid
                                                      }/${dateArray[0]}/${
                                                        dateArray[1]
                                                      }/${
                                                        dateArray[2]
                                                      }/Photographs/${
                                                        item?.Photograph?.split(
                                                          "_"
                                                        )[3]
                                                      }/${item?.Photograph}_${
                                                        initialEdition?.editionid
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                }
                                                alt={
                                                  articleViewDataLinked?.ArticleTitle
                                                }
                                              />

                                              {item?.ImageCaption && (
                                                <p
                                                  style={{
                                                    fontSize: `${articleBodyFontsize}px `,
                                                    width:
                                                      photographSize.width > 20
                                                        ? photographSize.width
                                                        : "auto",
                                                  }}
                                                  className="image-caption"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item &&
                                                      removeJunk(
                                                        item?.ImageCaption
                                                      ),
                                                  }}
                                                ></p>
                                              )}
                                            </Col>
                                          );
                                        }
                                      )
                                    : ""}
                                </Row>
                              )}
                          </div>
                        ) : (
                          <>
                            <Row
                              id={
                                !articleTextView
                                  ? "article-scroll-container"
                                  : ""
                              }
                              style={{
                                position: "relative",
                                width: "98%",
                                height: !isRegional && window.innerHeight - 120,
                              }}
                            >
                              <Col md="12">
                                {textData.length > 0 &&
                                  textData?.map((article, key) => {
                                    return (
                                      <React.Fragment key={article?.Text + key}>
                                        <div
                                          key={"articleName_" + article?.Text}
                                          className=""
                                          style={{
                                            backgroundColor: "#ffff0069",
                                            opacity: getOpacity(
                                              article,
                                              props?.isSearch?.search
                                            ),
                                            position: "absolute",
                                            top: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Top *
                                                  imageSizes.height) /
                                                2
                                              : " ",
                                            left: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Left *
                                                  imageSizes.width) /
                                                2
                                              : " ",
                                            height: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Height *
                                                  imageSizes.height) /
                                                2
                                              : " ",
                                            width: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Width *
                                                  imageSizes.width) /
                                                2
                                              : " ",
                                          }}
                                        ></div>
                                      </React.Fragment>
                                    );
                                  })}
                                <img // need to add highlight
                                  // style={{ width: `${imgWidth}%` }}
                                  onLoad={() => {
                                    setImageSizes({
                                      width:
                                        !isRegional &&
                                        imageSizeRef?.current?.naturalWidth,
                                      height:
                                        !isRegional &&
                                        imageSizeRef?.current?.naturalHeight,
                                    });
                                    setImageViewSize({
                                      width:
                                        imageViewRef?.current?.naturalWidth,
                                      height: "auto",
                                    });
                                  }}
                                  style={{
                                    width:
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageDimension?.width
                                        : `${imagesViewSize?.width}px`,
                                    height:
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageDimension.height
                                        : "auto",
                                  }}
                                  ref={
                                    props?.isSearch?.from === "photographs" ||
                                    props?.isSearch?.from === "advertisements"
                                      ? imageSizeRef
                                      : imageViewRef
                                  }
                                  crossOrigin="*"
                                  src={
                                    props?.isSearch?.from === "advertisements"
                                      ? `${s3Source}/PublicationData/${
                                          initialPublication?.publicationcode
                                        }/${initialEdition?.editionid}/${
                                          dateArray[0]
                                        }/${dateArray[1]}/${
                                          dateArray[2]
                                        }/Advertisement/${
                                          advertisement.current?.split("_")[3]
                                        }/${advertisement.current}_${
                                          initialEdition?.editionid
                                        }.jpg?v=${new Date().getTime()}`
                                      : articleViewName ||
                                        articleViewData?.ArticleName
                                      ? `${s3Source}/PublicationData/${
                                          initialPublication?.publicationcode
                                        }/${initialEdition?.editionid}/${
                                          dateArray[0]
                                        }/${dateArray[1]}/${
                                          dateArray[2]
                                        }/Article/${
                                          articleViewName?.split("_")[3]
                                        }/${
                                          articleViewData?.ArticleName ??
                                          articleViewName
                                        }.jpg?v=${new Date().getTime()}`
                                      : `${s3Source}/PublicationData/${
                                          initialPublication?.publicationcode
                                        }/${initialEdition?.editionid}/${
                                          dateArray[0]
                                        }/${dateArray[1]}/${
                                          dateArray[2]
                                        }/Advertisement/${
                                          advertisement.current?.split("_")[3]
                                        }/${advertisement.current}_${
                                          initialEdition?.editionid
                                        }.jpg?v=${new Date().getTime()}`
                                  }
                                  alt={articleViewData?.ArticleTitle}
                                />
                              </Col>
                              {articleViewDataLinked &&
                                Object.keys(articleViewDataLinked).length >
                                  0 && (
                                  <p className="mt-2">
                                    <img // need to add highlight
                                      // style={{ width: `${imgWidth}%` }}
                                      onLoad={() => {
                                        setImageSizes({
                                          width:
                                            !isRegional &&
                                            imageSizeRef?.current.naturalWidth,
                                          height:
                                            !isRegional &&
                                            imageSizeRef.current.naturalHeight,
                                        });
                                        setImageViewLinkSize({
                                          width:
                                            imageViewLinkRef.current
                                              .naturalWidth,
                                          height: "auto",
                                        });
                                      }}
                                      style={{
                                        width: props?.isSearch?.search
                                          ? imageDimension.width
                                          : articleViewDataLinked
                                          ? `${imagesViewLinkSize?.width}px`
                                          : "100%",

                                        height: props?.isSearch?.search
                                          ? imageDimension.height
                                          : "auto",
                                      }}
                                      ref={
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageSizeRef
                                          : imageViewLinkRef
                                      }
                                      crossOrigin="*"
                                      src={
                                        articleViewNameLinked &&
                                        `${s3Source}/PublicationData/${
                                          initialPublication?.publicationcode
                                        }/${initialEdition?.editionid}/${
                                          dateArray[0]
                                        }/${dateArray[1]}/${
                                          dateArray[2]
                                        }/Article/${
                                          articleViewNameLinked?.split("_")[3]
                                        }/${
                                          articleViewDataLinked?.ArticleName
                                        }.jpg?v=${new Date().getTime()}`
                                      }
                                      alt={articleViewDataLinked?.ArticleTitle}
                                    />
                                  </p>
                                )}
                            </Row>
                          </>
                        )}

                        <Col
                          className=" bottom-ad-article pt-3 mb-4"
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                        >
                          <Ad
                            path={`/1064661/Inside_Article_Bottom_Horizontal`}
                            width={728}
                            height={90}
                            divId={`div-gpt-ad-1640342579043-0`}
                          />
                        </Col>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </OutsideClickHandler>
        ) : (
          <OutsideClickHandler
            onOutsideClick={() => {
              props?.toggleArticleViewModal();
            }}
          >
            <Row>
              <Col sm="12">
                <Card className="px-2 border-0">
                  <CardBody className="pr-2">
                    <Row
                      className="justify-content-center pb-2 "
                      style={{ color: "#2b78a2" }}
                    >
                      <div className="share-mob d-flex">
                        <Row className="flex-wrap-inherit">
                          <Col
                            sm="2"
                            onClick={() => window.responsiveVoice.cancel()}
                          >
                            <Link to={"/maharashtratimes/home"}>
                              <img
                                style={{ maxWidth: "45px" }}
                                src="/maharashtratimes-assets/logo/mt.png"
                                alt="timesgroup"
                              />
                            </Link>
                          </Col>
                          <div className="col-sm-10 d-flex justify-content-center">
                            {currentPublication?.type !== "image" &&
                            !isRegional &&
                            !ad?.ZoneId &&
                            !hasInfoZone ? (
                              <Col
                                className="pt-0 px-4 cursor-pointer2"
                                style={{
                                  color: themeColor,
                                }}
                                onClick={() => {
                                  toggleView();
                                }}
                              >
                                {articleTextView ? (
                                  <div className="pt-1">
                                    <Tooltip title="Image">
                                      <Image />
                                    </Tooltip>
                                  </div>
                                ) : (
                                  <div className="pt-1">
                                    <Tooltip title="Text">
                                      <FileText />
                                    </Tooltip>
                                  </div>
                                )}
                              </Col>
                            ) : (
                              ""
                            )}
                            {articleTextView &&
                              ad?.length === 0 &&
                              !isRegional && (
                                <Col
                                  style={{
                                    color: themeColor,
                                  }}
                                  className="pt-1 px-3"
                                  onClick={() => {
                                    articleTextView && increaseFontSize();
                                    zoomImageSize(10, 90);
                                  }}
                                >
                                  <Tooltip title="Zoom In">
                                    <ZoomIn />
                                  </Tooltip>
                                </Col>
                              )}
                            {articleTextView &&
                              ad?.length === 0 &&
                              !isRegional && (
                                <Col
                                  style={{
                                    color: themeColor,
                                  }}
                                  className="pt-1 px-3"
                                  onClick={() => {
                                    articleTextView && decreaseFontSize();
                                    zoomImageSize(-10, -90);
                                  }}
                                >
                                  <Tooltip title="Zoom Out">
                                    <ZoomOut />
                                  </Tooltip>
                                </Col>
                              )}
                            <Col
                              className="pt-1 px-3"
                              style={{
                                color: themeColor,
                              }}
                            >
                              <Download onClick={saveImg} />
                            </Col>
                            {/* {currentPublication?.type !== "image" &&
                              !isRegional &&
                              !ad?.ZoneId && (
                                <Col
                                  style={{
                                    color: themeColor,
                                  }}
                                  className="pt-1 px-3"
                                  onClick={() => {
                                    setSpeechRunning(!speechRunning);
                                    synthesizeSpeech(TTS);
                                  }}
                                >
                                  {!speechRunning ? (
                                    <VolumeOff className="font-overview-icon" />
                                  ) : (
                                    <VolumeUpIcon className="font-overview-icon" />
                                  )}
                                </Col>
                              )} */}
                            <Col
                              style={{
                                color: themeColor,
                              }}
                              className="pt-1 px-3"
                              onClick={() => print("articleViewMob")}
                            >
                              <Tooltip title="Print">
                                <Printer color={themeColor} />
                              </Tooltip>
                            </Col>
                            {/* <Col
                              style={{
                                color: themeColor,
                              }}
                              className="pt-1 px-3"
                            >
                              <Tooltip title="Share">
                                <ShareIcon
                                  onClick={toggleShare}
                                  className="font-overview-icon"
                                />
                              </Tooltip>
                            </Col> */}
                          </div>
                          <Col></Col>
                        </Row>
                      </div>
                    </Row>
                    <Row className="justify-content-center mt-2">
                      <Collapse
                        isOpen={isOpenMobArticle}
                        className="collapse-fixed"
                      >
                        <Row className="pl-0 justify-content-center">
                          <Card className="social-share">
                            <CardBody>
                              <Row
                                className=""
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <WhatsappShareButton
                                  title={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "whatsapp"
                                    );
                                  }}
                                >
                                  <WhatsAppIcon
                                    style={{
                                      color: "#25D366",
                                      fontSize: "30px",
                                    }}
                                  />
                                </WhatsappShareButton>

                                <FacebookShareButton
                                  quote={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "facebook"
                                    );
                                  }}
                                >
                                  <FacebookIcon
                                    fontSize="large"
                                    style={{
                                      color: "#4267B2",
                                      fontSize: "30px",
                                    }}
                                  />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  title={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "twitter"
                                    );
                                  }}
                                >
                                  <TwitterIcon
                                    style={{
                                      color: "#1DA1F2",
                                      fontSize: "30px",
                                    }}
                                  />
                                </TwitterShareButton>
                                <EmailShareButton
                                  subject={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "email"
                                    );
                                  }}
                                >
                                  <img
                                    style={{ width: "25px" }}
                                    src="https://img.icons8.com/color/24/000000/gmail-new.png"
                                    alt="gmail"
                                  />
                                </EmailShareButton>

                                <div className="pt-2">
                                  <Copy
                                    onClick={() => shareArticle("copy")}
                                    fontSize="small"
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="pt-2">
                                  <X color="red" onClick={toggleShare} />
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Row>
                      </Collapse>
                    </Row>
                    <Row className="pt-4 ">
                      {loading ? (
                        <Spinner className="loaderArticleCard" size="sm" />
                      ) : articleTextView && !isRegional ? (
                        <Col className="text-center" id="articleViewMob">
                          {!ad?.ZoneId && (
                            <>
                              <div
                                className="badge p-1 mb-3 text-white"
                                style={{
                                  backgroundColor: "var(--primary)",
                                }}
                              >
                                Views: <span>{views}</span>
                              </div>
                              <div
                                className="badge p-1 mb-3 text-white ml-2"
                                style={{
                                  backgroundColor: "var(--primary)",
                                }}
                              >
                                Shares: <span>{totalShareCount ?? 0}</span>
                              </div>
                            </>
                          )}
                          {articleViewData?.Blurb && (
                            <Row
                              className="text-center"
                              style={{
                                justifyContent: "center",
                                color: "white",
                              }}
                            >
                              <h6
                                style={{
                                  backgroundColor: "red",

                                  fontSize: `${articleBlurbFontsize}px`,
                                }}
                                id="article-card-blurb-mob"
                                className="p-1"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.Blurb?.split("<br>`<br>")
                                      .join("₹")
                                      .split("<br/>`<br/>")
                                      .join("₹")
                                      .split("<br/>₹<br/>")
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">"),
                                }}
                              ></h6>
                            </Row>
                          )}
                          <h4
                            style={{
                              fontSize: `${articleHeadingMobFontsize}px`,
                            }}
                            id="article-card-title-mob"
                            className="font-weight-bold article-view-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                articleViewData?.ArticleTitle?.split(
                                  "<br>`<br>"
                                )
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt;")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">"),
                            }}
                          ></h4>
                          {articleViewData?.ColumnTitle && (
                            <h6
                              style={{
                                fontSize: `${articleBlurbFontsize}px`,
                                textAlign: "left",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData?.ColumnTitle?.split(
                                    "<br>`<br>"
                                  )
                                    .join("₹")
                                    .split("<br>`")
                                    .join("₹")
                                    .split("`<br>")
                                    .join("₹")
                                    .split("`")
                                    .join("₹")
                                    .split("<br>¤<br>")
                                    .join("€")
                                    .split("<br>¤")
                                    .join("€")
                                    .split("¤<br>")
                                    .join("€")
                                    .split("¤")
                                    .join("€")
                                    ?.split("<br>")
                                    ?.join("<br/>")
                                    .split("&lt")
                                    .join("<")
                                    .split("&gt;")
                                    .join(">"),
                              }}
                            ></h6>
                          )}
                          {articleViewData && (
                            <hr style={{ borderTop: "2px solid black" }} />
                          )}
                          {articleViewData?.Author && (
                            <p
                              // style={{
                              //   fontSize: `${articleBodyFontsize}px`,
                              // }}
                              style={{
                                fontSize: `${articleBlurbFontsize}px`,
                                fontStyle: "italic",
                                color: articleAuthorEmail.current
                                  ? "#0000ee"
                                  : "#000",
                                textDecoration: articleAuthorEmail.current
                                  ? "underline"
                                  : "none",
                                fontWeight: articleAuthorEmail.current
                                  ? "bold"
                                  : "normal",
                                fontFamily: "LibreBaskerville",
                                cursor: articleAuthorEmail.current
                                  ? "pointer"
                                  : "auto",
                                textAlign: "left",
                                marginBottom: "0px",
                              }}
                              onClick={() => {
                                if (articleAuthorEmail.current) {
                                  window.open(
                                    "mailto:" +
                                      extractEmail(articleViewData.Author),
                                    "mail"
                                  );
                                }
                              }}
                              id="article-card-author-mob"
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData?.Author &&
                                  extractEmail(articleViewData.Author),
                              }}
                            ></p>
                          )}
                          {articleViewData?.Author &&
                            articleAuthorEmail.current === false && (
                              <b
                                style={{}}
                                id="article-card-author"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.Author ===
                                      "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                      ? "TIMES NEWS NETWORK"
                                      : articleViewData.Author,
                                }}
                              ></b>
                            )}
                          {articleViewData?.ArticleEmail && (
                            <a href={"mailto:" + articleViewData?.ArticleEmail}>
                              <i
                                style={{
                                  fontSize: `${articleBodyFontsize}px`,
                                  textAlign: "left",
                                }}
                                id="article-card-mail-mob"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleEmail,
                                }}
                              ></i>
                            </a>
                          )}
                          {articleViewData &&
                            articleViewData?.ArticleSource && (
                              <b
                                style={{
                                  fontSize: `${articleBodyFontsize}px`,
                                  textAlign: "left",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleSource ===
                                      "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                      ? "TIMES NEWS NETWORK"
                                      : articleViewData?.Author?.split("<br>")
                                          ?.join(" ")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">"),
                                }}
                              ></b>
                            )}
                          {articleViewData &&
                            articleViewData?.ArticleLocation && (
                              <p
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <br />
                                <b
                                  style={{
                                    fontSize: `${articleBodyFontsize}px`,
                                    fontWeight: "bold",
                                    color: "#4a4949",
                                    textAlign: "left",
                                    fontFamily: "LibreBaskerville",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.ArticleLocation,
                                  }}
                                ></b>
                              </p>
                            )}
                          <p
                            style={{
                              fontSize: `${articleBodyFontsize - 2}px`,
                              lineHeight: `${articleBodyFontsize + 8}px`,
                              textAlign: "left",
                            }}
                            id="articlecardpara-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                parsedBody.current
                                  .split("")
                                  .join("›")
                                  .split("<br>`<br>")
                                  .join("₹")
                                  .split("<br/>`<br/>")
                                  .join("₹")
                                  .split("<br/>₹<br/>")
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">")
                                  ?.split("googleAds")?.[0],
                            }}
                            className="article-view-mob"
                          ></p>
                          {articleViewData &&
                            Object.keys(articleViewData)?.length > 0 &&
                            googleAds()}
                          <p
                            style={{
                              fontSize: `${articleBodyFontsize - 2}px`,
                              lineHeight: `${articleBodyFontsize + 8}px`,
                              textAlign: "left",
                              fontFamily: "LibreBaskerville",
                            }}
                            id="articlecardpara-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                parsedBody.current
                                  .split("")
                                  .join("›")
                                  .split("<br>`<br>")
                                  .join("₹")
                                  .split("<br/>`<br/>")
                                  .join("₹")
                                  .split("<br/>₹<br/>")
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">")
                                  ?.split("googleAds")?.[1],
                            }}
                            className="article-view-mob"
                          />

                          {relatedArticleData &&
                            relatedArticleData?.length > 0 &&
                            relatedArticleData?.map((item, ind) => {
                              return (
                                <div
                                  className={"greyBox"}
                                  key={item?.ArticleTitle}
                                >
                                  {item?.Blurb && (
                                    <Row
                                      className="text-center"
                                      style={{
                                        justifyContent: "center",
                                        color: "white",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          backgroundColor: "red",

                                          fontSize: `${articleBlurbFontsize}px`,
                                        }}
                                        id="article-card-blurb-mob"
                                        className="p-1"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item &&
                                            item?.Blurb?.split("<br>`<br>")
                                              .join("₹")
                                              .split("<br/>`<br/>")
                                              .join("₹")
                                              .split("<br/>₹<br/>")
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    </Row>
                                  )}
                                  <div>
                                    <h1
                                      id="article-card-title"
                                      style={{
                                        fontWeight: "700",
                                        fontSize: `${
                                          articleHeadingFontsize - 10
                                        }px`,
                                        fontFamily: "fff ff4 !important",
                                        lineHeight: `${
                                          articleHeadingFontsize + 10
                                        }px`,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item &&
                                          item?.ArticleTitle?.split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt;")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">"),
                                      }}
                                    />
                                    {item?.ColumnTitle && (
                                      <h6
                                        style={{
                                          fontSize: `${
                                            Number(articleBlurbFontsize) + 10
                                          }px`,
                                          fontWeight: "normal",
                                          fontStyle: "italic",
                                          color: "#4a4949",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item &&
                                            item?.ColumnTitle?.split(
                                              "<br>`<br>"
                                            )
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    )}
                                    {item && (
                                      <hr
                                        style={{
                                          borderTop: "2px solid black",
                                        }}
                                      />
                                    )}

                                    {item && item?.ArticleLocation && (
                                      <b
                                        style={{
                                          fontSize: `${articleBodyFontsize}px`,
                                          fontWeight: "bold",
                                          color: "#4a4949",
                                          fontFamily: "LibreBaskerville",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item && item?.ArticleLocation,
                                        }}
                                      ></b>
                                    )}

                                    <p
                                      style={{
                                        fontSize: `${
                                          articleBodyFontsize + 1
                                        }px `,
                                        lineHeight: `${
                                          Number(articleBodyFontsize) + 15
                                        }px`,
                                        color: "#4a4949",
                                      }}
                                      id="articlecardpara"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item &&
                                          item?.ArticleBody?.split("")
                                            .join("›")
                                            .split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">")
                                            ?.split("googleAdDivHere")
                                            .join(" "),
                                      }}
                                      className="mt-2"
                                    >
                                      {/* November 7, 2020 */}
                                      {/* <br /> */}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}

                          {/* end */}

                          {/* second linked article*/}
                          {articleViewDataLinked &&
                            Object.keys(articleViewDataLinked).length > 0 && (
                              <>
                                <h4
                                  style={{
                                    fontSize: `${articleHeadingMobFontsize}px`,
                                  }}
                                  id="article-card-title2-mob"
                                  className="font-weight-bold article-view-mob mt-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      articleViewDataLinked?.ArticleTitle?.split(
                                        "`"
                                      )
                                        .join("₹")
                                        .split("<br>`<br>")
                                        .join("₹")
                                        .split("<br/>`<br/>")
                                        .join("₹")
                                        .split("<br/>₹<br/>")
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt;")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">"),
                                  }}
                                ></h4>
                                {articleViewDataLinked?.ColumnTitle && (
                                  <h6
                                    style={{
                                      fontSize: `${articleBlurbFontsize}px`,
                                      textAlign: "left",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked?.ColumnTitle?.split(
                                          "<br>`<br>"
                                        )
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">"),
                                    }}
                                  ></h6>
                                )}
                                <hr style={{ borderTop: "2px solid black" }} />
                                {articleViewDataLinked?.Author && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                      textAlign: "left",
                                    }}
                                    id="article-card-author2-mob"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked?.Author ===
                                          "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? "TIMES NEWS NETWORK"
                                          : articleViewDataLinked?.Author?.split(
                                              "<br>"
                                            )
                                              ?.join(" ")
                                              .split("&lt;")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                    }}
                                  ></b>
                                )}
                                {articleViewDataLinked?.ArticleEmail && (
                                  <a
                                    href={
                                      "mailto:" +
                                      articleViewDataLinked?.ArticleEmail
                                    }
                                  >
                                    <i
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                      }}
                                      id="article-card-mail-mob"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleEmail,
                                      }}
                                    ></i>
                                  </a>
                                )}
                                {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticleSource && (
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked.ArticleSource ===
                                            "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                            ? "TIMES NEWS NETWORK"
                                            : articleViewDataLinked?.Author?.split(
                                                "<br>"
                                              )
                                                ?.join(" ")
                                                .split("&lt;")
                                                .join("<")
                                                .split("&gt;")
                                                .join(">"),
                                      }}
                                    ></b>
                                  )}
                                {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticleLocation && (
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                        fontFamily: "LibreBaskerville",
                                        fontWeight: "bold",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleLocation,
                                      }}
                                    ></b>
                                  )}
                                <p
                                  style={{
                                    fontSize: `${articleBodyFontsize - 2}px`,
                                    lineHeight: `${articleBodyFontsize + 8}px`,
                                    fontFamily: "LibreBaskerville",
                                    textAlign: "left",
                                  }}
                                  id="articlecardpara-mob"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      parsedLinkedBody?.current
                                        ?.split("<br>`<br>")
                                        .join("₹")
                                        .split("<br/>`<br/>")
                                        .join("₹")
                                        .split("<br/>₹<br/>")
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">")
                                        ?.split("googleAdDivHere")
                                        .join(" "),
                                  }}
                                  className="article-view-mob"
                                />
                              </>
                            )}
                          <div>
                            {articleViewData &&
                            articleViewData?.ArticlePhotographs &&
                            articleViewData?.ArticlePhotographs.length
                              ? articleViewData?.ArticlePhotographs.map(
                                  (item, index) => {
                                    let photo = item?.Photograph?.split("_");
                                    return (
                                      <Col
                                        xs="12"
                                        sm="12"
                                        md="12 "
                                        lg="12"
                                        xl="12"
                                        className="text-center"
                                        style={{ overflow: "auto" }}
                                      >
                                        <img
                                          onLoad={() => {
                                            setPhotographSize({
                                              width:
                                                photographRef.current
                                                  .naturalWidth,
                                              height: "auto",
                                            });
                                          }}
                                          key={
                                            articleViewData?.ArticleName +
                                            index +
                                            "name"
                                          }
                                          ref={photographRef}
                                          crossOrigin="*"
                                          className=""
                                          src={
                                            photo[photo.length - 1] ===
                                            initialEdition?.editionid
                                              ? `${s3Source}/PublicationData/${
                                                  initialPublication?.publicationcode
                                                }/${
                                                  initialEdition?.editionid
                                                }/${dateArray[0]}/${
                                                  dateArray[1]
                                                }/${dateArray[2]}/Photographs/${
                                                  articleViewData?.ArticleName?.split(
                                                    "_"
                                                  )[3]
                                                }/${item?.Photograph}.jpg?v=${
                                                  filteredDate?.Timestamp
                                                }`
                                              : `${s3Source}/PublicationData/${
                                                  initialPublication?.publicationcode
                                                }/${
                                                  initialEdition?.editionid
                                                }/${dateArray[0]}/${
                                                  dateArray[1]
                                                }/${dateArray[2]}/Photographs/${
                                                  articleViewData?.ArticleName?.split(
                                                    "_"
                                                  )[3]
                                                }/${item?.Photograph}_${
                                                  initialEdition?.editionid
                                                }.jpg?v=${
                                                  filteredDate?.Timestamp
                                                }`
                                          }
                                          alt={articleViewData?.ArticleTitle}
                                        />

                                        {item?.ImageCaption && (
                                          <p
                                            style={{
                                              fontSize: `${articleBodyFontsize}px `,
                                              width: photographSize.width
                                                ? photographSize.width
                                                : "200px",
                                            }}
                                            className="image-caption"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item &&
                                                removeJunk(item?.ImageCaption),
                                            }}
                                          ></p>
                                        )}
                                      </Col>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                          {ad && advertisement?.current && (
                            <Row style={{ overflow: "auto" }}>
                              <TransformWrapper
                                // limitToBounds={true}
                                // doubleClick={{ step: 0.5 }}
                                initialScale={1}
                                doubleClick={{ disabled: true }}
                                wheel={{ disabled: true }}
                                panning={{ disabled: false }}
                                // minPositionY={"1000px"}
                              >
                                <TransformComponent wrapperClass="react-transform-wrapper-1">
                                  <div
                                    className="text-center"
                                    style={{ overflow: "auto" }}
                                  >
                                    <img
                                      width={`${Number(imgWidth) + 20}%`}
                                      crossOrigin="*"
                                      className=""
                                      src={`${s3Source}/PublicationData/${
                                        initialPublication?.publicationcode
                                      }/${initialEdition?.editionid}/${
                                        dateArray[0]
                                      }/${dateArray[1]}/${
                                        dateArray[2]
                                      }/Advertisement/${
                                        advertisement.current?.split("_")[3]
                                      }/${advertisement.current}_${
                                        initialEdition?.editionid
                                      }.jpg?v=${new Date().getTime()}`}
                                      alt={articleViewData?.ArticleTitle}
                                    />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                  </div>
                                </TransformComponent>
                              </TransformWrapper>
                            </Row>
                          )}
                          {/* second linked article data */}
                          {articleViewDataLinked &&
                            Object.keys(articleViewDataLinked)?.length > 0 && (
                              <>
                                <div>
                                  {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticlePhotographs &&
                                  articleViewDataLinked?.ArticlePhotographs
                                    .length
                                    ? articleViewDataLinked?.ArticlePhotographs.map(
                                        (item, index) => {
                                          let photo =
                                            item?.Photograph?.split("_");
                                          return (
                                            <React.Fragment className="text-center">
                                              <img
                                                onLoad={() => {
                                                  setPhotographSize({
                                                    width:
                                                      photographRef.current
                                                        .naturalWidth,
                                                    height: "auto",
                                                  });
                                                }}
                                                key={
                                                  articleViewDataLinked?.ArticleName +
                                                  index +
                                                  "name"
                                                }
                                                crossOrigin="*"
                                                className=""
                                                ref={photographRef}
                                                src={
                                                  photo[photo.length - 1] ===
                                                  initialEdition?.editionid
                                                    ? `${s3Source}/PublicationData/${
                                                        initialPublication?.publicationcode
                                                      }/${
                                                        initialEdition?.editionid
                                                      }/${dateArray[0]}/${
                                                        dateArray[1]
                                                      }/${
                                                        dateArray[2]
                                                      }/Photographs/${
                                                        articleViewDataLinked?.ArticleName?.split(
                                                          "_"
                                                        )[3]
                                                      }/${
                                                        item?.Photograph
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                    : `${s3Source}/PublicationData/${
                                                        initialPublication?.publicationcode
                                                      }/${
                                                        initialEdition?.editionid
                                                      }/${dateArray[0]}/${
                                                        dateArray[1]
                                                      }/${
                                                        dateArray[2]
                                                      }/Photographs/${
                                                        articleViewDataLinked?.ArticleName?.split(
                                                          "_"
                                                        )[3]
                                                      }/${item?.Photograph}_${
                                                        initialEdition?.editionid
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                }
                                                alt={
                                                  articleViewDataLinked?.ArticleTitle
                                                }
                                              />

                                              {item?.ImageCaption && (
                                                <p
                                                  style={{
                                                    fontSize: `${articleBodyFontsize}px `,
                                                    width: photographSize.width,
                                                  }}
                                                  className="image-caption"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item &&
                                                      removeJunk(
                                                        item?.ImageCaption
                                                      ),
                                                  }}
                                                ></p>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </>
                            )}
                        </Col>
                      ) : (
                        <>
                          <Row
                            // className="article-view-desktop"
                            // height={"40rem"}
                            style={{
                              //   width: "1000px",
                              position: "relative",
                              overflowX: "scroll",
                              overflowY: "scroll",
                              //   transform:
                              //     isPopularDrawerOpen && isFitToHeight > 0 ? "translateX(-5vw)" : "",
                            }}
                            noGutters
                            // style={{ width: "fit-content" }}
                            // id="article-view-card"
                          >
                            <TransformWrapper
                              doubleClick={{ disabled: true }}
                              initialScale={1}
                              wheel={{ disabled: true }}
                            >
                              <TransformComponent wrapperClass="react-transform-wrapper-1">
                                <Col id="articleViewMob">
                                  {textData.length > 0 &&
                                    textData?.map((article, key) => {
                                      return (
                                        <React.Fragment
                                          key={key + article?.Text}
                                        >
                                          <div
                                            key={"llf" + key}
                                            className=""
                                            style={{
                                              backgroundColor: "#ffff0069",
                                              opacity: getOpacity(
                                                article,
                                                props?.isSearch?.search
                                              ),
                                              position: "absolute",
                                              top: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Top *
                                                    imageSizes.height) /
                                                  2
                                                : " ",
                                              left: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Left *
                                                    imageSizes.width) /
                                                  2
                                                : " ",
                                              height: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Height *
                                                    imageSizes.height) /
                                                  2
                                                : " ",
                                              width: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Width *
                                                    imageSizes.width) /
                                                  2
                                                : " ",
                                            }}
                                          ></div>
                                        </React.Fragment>
                                      );
                                    })}
                                  <img // need to add highlight
                                    // style={{ width: `${imgWidth}%` }}
                                    onLoad={() => {
                                      setImageSizes({
                                        width:
                                          !isRegional &&
                                          imageSizeRef.current.naturalWidth,
                                        height:
                                          !isRegional &&
                                          imageSizeRef.current.naturalHeight,
                                      });
                                      setImageViewSize({
                                        width:
                                          imageViewRef.current.naturalWidth,
                                        height: "auto",
                                      });
                                    }}
                                    style={{
                                      width:
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageDimension?.width
                                          : `${imagesViewSize?.width}px`, //will use later for highlation and original image size
                                      // `${Number(imgWidth) + 20}%`,

                                      // "inherit",
                                      height:
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageDimension.height
                                          : "auto",
                                      marginTop: "50px",
                                    }}
                                    ref={
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageSizeRef
                                        : imageViewRef
                                    }
                                    crossOrigin="*"
                                    src={
                                      props?.isSearch?.from === "advertisements"
                                        ? `${s3Source}/PublicationData/${
                                            initialPublication?.publicationcode
                                          }/${initialEdition?.editionid}/${
                                            dateArray[0]
                                          }/${dateArray[1]}/${
                                            dateArray[2]
                                          }/Advertisement/${
                                            advertisement.current?.split("_")[3]
                                          }/${advertisement.current}_${
                                            initialEdition?.editionid
                                          }.jpg?v=${new Date().getTime()}`
                                        : articleViewName ||
                                          articleViewData?.ArticleName
                                        ? `${s3Source}/PublicationData/${
                                            initialPublication?.publicationcode
                                          }/${initialEdition?.editionid}/${
                                            dateArray[0]
                                          }/${dateArray[1]}/${
                                            dateArray[2]
                                          }/Article/${
                                            articleViewName?.split("_")[3]
                                          }/${
                                            articleViewData?.ArticleName ??
                                            articleViewName
                                          }.jpg?v=${new Date().getTime()}`
                                        : `${s3Source}/PublicationData/${
                                            initialPublication?.publicationcode
                                          }/${initialEdition?.editionid}/${
                                            dateArray[0]
                                          }/${dateArray[1]}/${
                                            dateArray[2]
                                          }/Advertisement/${
                                            advertisement.current?.split("_")[3]
                                          }/${advertisement.current}_${
                                            initialEdition?.editionid
                                          }.jpg?v=${new Date().getTime()}`
                                    }
                                    alt={articleViewData?.ArticleTitle}
                                  />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  {/* <p>hey i shoud be visible</p> */}

                                  {articleViewDataLinked &&
                                    Object.keys(articleViewDataLinked).length >
                                      0 && (
                                      <div style={{ overflow: "auto" }}>
                                        <img
                                          onLoad={() => {
                                            setImageSizes({
                                              width:
                                                !isRegional &&
                                                imageSizeRef.current
                                                  .naturalWidth,
                                              height:
                                                !isRegional &&
                                                imageSizeRef.current
                                                  .naturalHeight,
                                            });
                                            setImageViewLinkSize({
                                              width:
                                                imageViewLinkRef.current
                                                  .naturalWidth,
                                              height: "auto",
                                            });
                                          }}
                                          style={{
                                            width: props?.isSearch?.search
                                              ? imageDimension.width
                                              : articleViewDataLinked
                                              ? `${imagesViewLinkSize?.width}px`
                                              : "100%",

                                            height: props?.isSearch?.search
                                              ? imageDimension.height
                                              : "auto",
                                          }}
                                          className="mt-3"
                                          ref={
                                            props?.isSearch?.from ===
                                            "photographs"
                                              ? imageSizeRef
                                              : imageViewLinkRef
                                          }
                                          crossOrigin="*"
                                          src={`${s3Source}/PublicationData/${
                                            initialPublication?.publicationcode
                                          }/${initialEdition?.editionid}/${
                                            dateArray[0]
                                          }/${dateArray[1]}/${
                                            dateArray[2]
                                          }/Article/${
                                            articleViewNameLinked?.split("_")[3]
                                          }/${
                                            articleViewDataLinked?.ArticleName
                                          }.jpg?v=${new Date().getTime()}`}
                                          alt={
                                            articleViewDataLinked?.ArticleTitle
                                          }
                                        />
                                      </div>
                                    )}
                                </Col>
                              </TransformComponent>
                            </TransformWrapper>
                          </Row>
                        </>
                      )}

                      <Col
                        className=" bottom-ad-article  px-0 "
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                      >
                        <DFPSlotsProvider dfpNetworkId="1064661/Inside_Article_Bottom_Horizontal">
                          <AdSlot
                            sizes={[[300, 100]]}
                            adUnit="test/refreshable"
                          />
                        </DFPSlotsProvider>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </OutsideClickHandler>
        )}
        {/* </div> */}
      </Container>
    </React.Fragment>
  );
}
if (typeof window !== "undefined") {
  DFPManager.load();
}

const Ad = ({ path, width, height, divId }) => {
  const id = React.useMemo(() => `${divId}`, []);
  // G-KQRTLHVQH8
  React.useEffect(() => {
    const slot = GPTAdsManager.createSlot(path, width, height, id);
    slot.display();

    return () => {
      slot.destroy();
    };
  }, [path, width, height]);
  return <div id={id} />;
};
